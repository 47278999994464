/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetLastEditorDto
 */
export interface GetLastEditorDto {
    /**
     * 
     * @type {Date}
     * @memberof GetLastEditorDto
     */
    ts?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetLastEditorDto
     */
    owner?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetLastEditorDto
     */
    creationTs?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetLastEditorDto
     */
    creator?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetLastEditorDto
     */
    additionalTs?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetLastEditorDto
     */
    additionalOwner?: string;
}

export function GetLastEditorDtoFromJSON(json: any): GetLastEditorDto {
    return GetLastEditorDtoFromJSONTyped(json, false);
}

export function GetLastEditorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLastEditorDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ts': !exists(json, 'ts') ? undefined : (new Date(json['ts'])),
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'creationTs': !exists(json, 'creationTs') ? undefined : (new Date(json['creationTs'])),
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'additionalTs': !exists(json, 'additionalTs') ? undefined : (new Date(json['additionalTs'])),
        'additionalOwner': !exists(json, 'additionalOwner') ? undefined : json['additionalOwner'],
    };
}

export function GetLastEditorDtoToJSON(value?: GetLastEditorDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ts': value.ts === undefined ? undefined : (value.ts.toISOString()),
        'owner': value.owner,
        'creationTs': value.creationTs === undefined ? undefined : (value.creationTs.toISOString()),
        'creator': value.creator,
        'additionalTs': value.additionalTs === undefined ? undefined : (value.additionalTs.toISOString()),
        'additionalOwner': value.additionalOwner,
    };
}


