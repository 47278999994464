import React from 'react';

import { GetExamTypeDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetExamTypeDto';
import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';

interface ExamTypesProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onClick: (id: string) => void;
  refresh: boolean;
}

export const examTypeForGrid = (examType: GetExamTypeDto) => {
  return {
    key: examType.id,
    values: {
      'examType.name': examType.name,
      'category.name': examType?.category?.name,
      'examType.description': examType.description,
      'examType.price': examType.price,
      'examType.popular': examType.popular ? 'TAK' : 'NIE',
    },
  };
};

export const ExamTypes: React.FC<ExamTypesProps> = props => {
  const columns = [
    { header: 'Kategoria', property: 'category.name' },
    { header: 'Nazwa', property: 'examType.name' },
    { header: 'Opis', property: 'examType.description' },
    { header: 'Cena', property: 'examType.price' },
    { header: 'Popularny', property: 'examType.popular' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      onRowClick={props.onClick}
      showFilter={true}
      initialOrderColumn="examType.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak typów badań w systemie"
      enableMultiSelect={false}
      refresh={props.refresh}
    />
  );
};
