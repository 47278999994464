import React, { useEffect } from 'react';
import { Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { ReferralsList } from '../../components/Referrals/ReferralsList';
import { useSzafir } from '../../../common/hooks/useSzafir';
import { useAuth } from '../../../common/hooks/useAuth';

export const ReferralsView: React.FC = () => {
  const szafir = useSzafir();
  const auth = useAuth();

  useEffect(() => {
    if (auth.canSign) {
      szafir.activate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.canSign]);

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="referrals" className="mb-3">
        <Tab eventKey="search" title="Skierowania" className="m-3">
          <ReferralsList />
        </Tab>
      </Tabs>
    </Card>
  );
};
