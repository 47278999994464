import React, { useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import {
  CreateUpdateExamTypeCategoryDto,
  GetExamTypeCategoryDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import {
  examTypeCategoryData,
  validate,
} from '../../../common/spdCore/contexts/ExamTypeCategoryContext';
import { ExamTypeCategorySearch } from '../../components/ExamTypeCategory/ExamTypeCategorySearch';
import { ExamTypeCategoryEditor } from '../../components/ExamTypeCategory/ExamTypeCategoryEditor';

export const ExamTypesCategoriesView: React.FC = () => {
  const spd = useSpdCore();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [examTypeCategory, setExamTypeCategory] =
    useState<GetExamTypeCategoryDto>();

  const handleAdd = () => {
    setShow(true);
    setExamTypeCategory(undefined);
  };

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const addExamTypeCategory = async (
    etc: CreateUpdateExamTypeCategoryDto,
    id?: string,
  ) => {
    const status = await validate(etc);
    if (status.valid) {
      try {
        if (id) {
          await spd.examTypesCategories.update(id, etc);
        } else {
          await spd.examTypesCategories.create(etc);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const onRowClick = async (id: string) => {
    const getETC = await spd.examTypesCategories.get(id);
    setExamTypeCategory(getETC);
    setShow(true);
  };

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="examTypesCategories" className="mb-3">
        <Tab eventKey="search" title="Kategorie typów badań" className="m-3">
          <ExamTypeCategorySearch
            getData={spd.examTypesCategories.getAllByPortion}
            getCount={spd.examTypesCategories.getAllCount}
            refresh={refresh}
            onRowClick={onRowClick}
          />
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowa kategoria typów badań
          </Button>
          <ExamTypeCategoryEditor
            id={examTypeCategory?.id}
            examTypeCategory={examTypeCategoryData(examTypeCategory)}
            onCompleted={addExamTypeCategory}
            show={show}
            handleClose={handleClose}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
