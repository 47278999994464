import React, { useMemo, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  CreateUpdateUserDto,
  GetUserDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import {
  userData,
  validate,
} from '../../../common/spdCore/contexts/UserContext';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { UserEditor } from '../../components/Users/UserEditor';
import { Users } from '../../components/Users/Users';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';

export const UsersView: React.FC = () => {
  const spd = useSpdCore();

  const [show, setShow] = useState(false);
  const [user, setUser] = useState<GetUserDto>();

  const [refresh, setRefresh] = useState<boolean>(false);

  const handleAdd = () => {
    setShow(true);
    setUser(undefined);
  };

  const addEditUser = async (userCU: CreateUpdateUserDto, id?: string) => {
    const status = await validate(userCU);
    if (status.valid) {
      try {
        if (id) {
          await spd.users.update(id, userCU);
        } else {
          await spd.users.create(userCU);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const handleClick = async (id: string) => {
    const getUser = await spd.users.getById(id);
    setUser(getUser);
    setShow(true);
  };

  const getData = useMemo(
    () => (mode?: string) =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        spd.users.getAllByPortion(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          mode,
        )) as GridGetterFunction,
    [spd.users],
  );

  const getCount = useMemo(
    () => (mode?: string) =>
      (filterText =>
        spd.users.getAllCount(filterText, mode)) as GridCountFunction,
    [spd.users],
  );

  return (
    <Card>
      <Tabs defaultActiveKey="all" id="users" className="mb-3">
        <Tab eventKey="all" title="Wszyscy" className="m-3">
          <Users
            getData={getData()}
            getCount={getCount()}
            onRowClick={handleClick}
            refresh={refresh}
          />
        </Tab>
        <Tab eventKey="workers" title="Pracownicy" className="m-3">
          <Users
            getData={getData('workers')}
            getCount={getCount('workers')}
            onRowClick={handleClick}
            refresh={refresh}
          />
        </Tab>
        <Tab eventKey="examined" title="Badani" className="m-3">
          <Users
            getData={getData('examined')}
            getCount={getCount('examined')}
            onRowClick={handleClick}
            refresh={refresh}
          />
        </Tab>
        <Tab eventKey="external" title="Zewnętrzni" className="m-3">
          <Users
            getData={getData('external')}
            getCount={getCount('external')}
            onRowClick={handleClick}
            refresh={refresh}
          />
        </Tab>
      </Tabs>
      <div className="m-3 mt-0">
        <Button variant="outline-primary" onClick={handleAdd}>
          Nowy użytkownik
        </Button>
      </div>
      <UserEditor
        id={user?.id}
        user={userData(user)}
        healthcareFacilities={user?.usersInHealthcareFacility
          ?.filter(o => o !== null && o !== undefined)
          ?.map(hf => hf.healthcareFacility)}
        onCompleted={addEditUser}
        show={show}
        handleClose={handleClose}
      />
    </Card>
  );
};
