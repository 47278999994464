/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetKeyTestDto,
    GetKeyTestDtoFromJSON,
    GetKeyTestDtoFromJSONTyped,
    GetKeyTestDtoToJSON,
    GetTestDto,
    GetTestDtoFromJSON,
    GetTestDtoFromJSONTyped,
    GetTestDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetIndicatorTestRecalculatedDto
 */
export interface GetIndicatorTestRecalculatedDto {
    /**
     * 
     * @type {string}
     * @memberof GetIndicatorTestRecalculatedDto
     */
    id: string;
    /**
     * 
     * @type {GetTestDto}
     * @memberof GetIndicatorTestRecalculatedDto
     */
    test?: GetTestDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetIndicatorTestRecalculatedDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetIndicatorTestRecalculatedDto
     */
    mode?: GetIndicatorTestRecalculatedDtoModeEnum;
    /**
     * 
     * @type {GetKeyTestDto}
     * @memberof GetIndicatorTestRecalculatedDto
     */
    keyTest?: GetKeyTestDto | null;
    /**
     * 
     * @type {number}
     * @memberof GetIndicatorTestRecalculatedDto
     */
    result?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetIndicatorTestRecalculatedDto
     */
    normName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetIndicatorTestRecalculatedDto
     */
    recalculatedResult?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetIndicatorTestRecalculatedDto
     */
    interpretedResult?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetIndicatorTestRecalculatedDto
     */
    note?: string | null;
}

export function GetIndicatorTestRecalculatedDtoFromJSON(json: any): GetIndicatorTestRecalculatedDto {
    return GetIndicatorTestRecalculatedDtoFromJSONTyped(json, false);
}

export function GetIndicatorTestRecalculatedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetIndicatorTestRecalculatedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'test': !exists(json, 'test') ? undefined : GetTestDtoFromJSON(json['test']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'keyTest': !exists(json, 'keyTest') ? undefined : GetKeyTestDtoFromJSON(json['keyTest']),
        'result': !exists(json, 'result') ? undefined : json['result'],
        'normName': !exists(json, 'normName') ? undefined : json['normName'],
        'recalculatedResult': !exists(json, 'recalculatedResult') ? undefined : json['recalculatedResult'],
        'interpretedResult': !exists(json, 'interpretedResult') ? undefined : json['interpretedResult'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function GetIndicatorTestRecalculatedDtoToJSON(value?: GetIndicatorTestRecalculatedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'test': GetTestDtoToJSON(value.test),
        'name': value.name,
        'mode': value.mode,
        'keyTest': GetKeyTestDtoToJSON(value.keyTest),
        'result': value.result,
        'normName': value.normName,
        'recalculatedResult': value.recalculatedResult,
        'interpretedResult': value.interpretedResult,
        'note': value.note,
    };
}

/**
* @export
* @enum {string}
*/
export enum GetIndicatorTestRecalculatedDtoModeEnum {
    NumberOfCorrectAnswers = 'NumberOfCorrectAnswers',
    SumOfPresentationTime = 'SumOfPresentationTime',
    WeightedSum = 'WeightedSum'
}


