/*eslint max-lines-per-function: ["error", 530]*/
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { Accordion, Button, Form, FormGroup } from 'react-bootstrap';
import Select from 'react-select';

import FormRow from '../../../app/components/FormRow';
import {
  GetArticleDto,
  GetExamDto,
  GetPdfTemplateDtoTypeEnum,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { GetJudgmentDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetJudgmentDto';
import {
  ArticleMode,
  DrivingLicenseCategory,
  OccupationalMedicineCategory,
} from '../../../common/spdCore/validation/schemas';
import momencik from '../../../common/momencik';
import { ChooseSigned } from '../Users/ChooseSigned';
import { Role } from '../../../common/spdCore/authorization/roles';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { FormControlTextarea3 } from '../../../app/components/FormControlTextarea';
import LastEditorInfo from '../../../app/components/LastEditorInfo';

import { SaveAndPrintJudgment } from './SaveAndPrintJudgment';

interface JudgmentEditorProps {
  exam: GetExamDto;
  judg: Partial<GetJudgmentDto>;
  articles: GetArticleDto[];
  roles: Role[];
  handleOnClick: () => void;
  idx: string;
  mode: ArticleMode;
}

export const JudgmentEditor: React.FC<JudgmentEditorProps> = props => {
  const [judgment, setJudgment] = useState<Partial<GetJudgmentDto>>(props.judg);
  const [savingInProgress, setSavingInProgress] = useState(false);

  const spd = useSpdCore();

  useEffect(() => {
    if (
      judgment.article?.pdfTemplate?.type ===
        GetPdfTemplateDtoTypeEnum.DetektywOrzeczenieLekarskie &&
      judgment.judgmentDate
    ) {
      const dateIn5Years = new Date(judgment.judgmentDate);
      dateIn5Years.setFullYear(dateIn5Years.getFullYear() + 5);
      dateIn5Years.setDate(dateIn5Years.getDate() - 1);
      setJudgment({
        ...judgment,
        nextExamDate: dateIn5Years,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [judgment.article, judgment.judgmentDate]);

  const getAllArticles = () => {
    const arr = props.articles.map(article => {
      return {
        value: article.id,
        label: article.description,
      };
    });
    return arr;
  };

  const getAllMedOccup = () => {
    const arr = Object.keys(OccupationalMedicineCategory).map(name => {
      return {
        value: name,
        label:
          OccupationalMedicineCategory[
            name as keyof typeof OccupationalMedicineCategory
          ],
      };
    });
    return arr;
  };

  const getAllDrivingCategories = () => {
    const arr = Object.keys(DrivingLicenseCategory).map(name => {
      return {
        value: name,
        label:
          DrivingLicenseCategory[name as keyof typeof DrivingLicenseCategory],
      };
    });
    return arr;
  };

  const getSelectedDrivingCategories = () => {
    return getAllDrivingCategories().filter(item =>
      judgment.drivingLicense?.split(',')?.includes(item.value),
    );
  };

  const viewDocument = () => {
    if (!judgment.id) {
      return;
    }
    spd.pdfPrinter
      .loadAndPrintJudgment(judgment.id)
      .catch(error =>
        console.log(`Wystąpił problem z wydrukiem orzeczenia. ${error}`),
      );
  };

  return (
    <Accordion.Item eventKey={props.idx} className="flex-fill">
      <Accordion.Header onClick={props.handleOnClick}>
        <strong>
          {judgment.id
            ? `${judgment.number || '---'}, ${
                judgment?.article?.description ||
                OccupationalMedicineCategory[
                  judgment?.occupationalMedicineOneCategory as keyof typeof OccupationalMedicineCategory
                ] ||
                ''
              }`
            : 'WYPEŁNIJ DANE W ORZECZENIU I ZAPISZ!'}
        </strong>
      </Accordion.Header>
      <Accordion.Body>
        <Form className="d-grid gap-3">
          <FormRow controlId="staff" label="Lekarz">
            <ChooseSigned
              userUID={judgment?.psychologist?.uid}
              handleOnChange={user =>
                setJudgment({
                  ...judgment,
                  psychologist: user,
                })
              }
              roles={props.roles}
              isDisabled={judgment?.number ? true : false}
            />
          </FormRow>
          <FormRow controlId="article" label="Podstawa badania">
            <Select
              options={getAllArticles()}
              defaultValue={{
                value: judgment?.article?.id,
                label: judgment?.article?.description,
              }}
              isDisabled={judgment?.number ? true : false}
              onChange={options =>
                setJudgment({
                  ...judgment,
                  article: props.articles.find(a => a.id === options?.value),
                })
              }
            />
          </FormRow>
          {props.mode === ArticleMode.Psychologist && (
            <>
              <FormRow controlId="occupMed" label="Medycyna pracy">
                <Select
                  options={getAllMedOccup()}
                  defaultValue={{
                    value: judgment?.occupationalMedicineOneCategory,
                    label:
                      OccupationalMedicineCategory[
                        judgment?.occupationalMedicineOneCategory as keyof typeof OccupationalMedicineCategory
                      ],
                  }}
                  onChange={options =>
                    setJudgment({
                      ...judgment,
                      occupationalMedicineOneCategory: Object.keys(
                        OccupationalMedicineCategory,
                      ).find(a => a === options?.value),
                    })
                  }
                />
              </FormRow>
              <FormRow controlId="occupMedText" label="Medycyna pracy - inne">
                <Form.Control
                  type="text"
                  defaultValue={judgment?.occupationalMedicineText || ''}
                  onChange={e =>
                    setJudgment({
                      ...judgment,
                      occupationalMedicineText: e.target.value,
                    })
                  }
                />
              </FormRow>
            </>
          )}
          <FormRow controlId="number" label="Numer">
            <Form.Control
              type="text"
              disabled={true}
              value={
                judgment?.id
                  ? judgment?.number || ' ---- '
                  : '-- Numer zostanie nadany automatycznie po zapisie --'
              }
              onChange={e =>
                setJudgment({
                  ...judgment,
                  number: e.target.value,
                })
              }
            />
          </FormRow>
          <FormRow controlId="dateJudgment" label="Data orzeczenia">
            <Form.Control
              type="date"
              name="datepic"
              placeholder="DateRange"
              value={momencik(judgment.judgmentDate, 'YYYY-MM-DD')}
              onChange={e =>
                setJudgment({
                  ...judgment,
                  judgmentDate: new Date(e.target.value),
                })
              }
            />
          </FormRow>
          <FormRow controlId="pass" label="Wynik pozytywny">
            <Form.Check
              type="checkbox"
              defaultChecked={
                judgment?.pass ||
                judgment?.pass === undefined ||
                judgment?.pass === null
                  ? true
                  : false
              }
              onChange={e =>
                setJudgment({
                  ...judgment,
                  pass: e.target.checked,
                })
              }
            />
          </FormRow>
          <FormRow
            controlId="drivingCategories"
            label="Dotyczy kategorii prawa jazdy"
          >
            <Select
              options={getAllDrivingCategories()}
              value={getSelectedDrivingCategories()}
              isMulti
              className="test-multi-select"
              classNamePrefix="driving-select"
              onChange={options =>
                setJudgment({
                  ...judgment,
                  drivingLicense: options.map(o => o.value).join(','),
                })
              }
            />
          </FormRow>

          <FormRow controlId="nextExamDate" label="Data kolejnego badania">
            <Form.Control
              type="date"
              required={false}
              name="datepic"
              placeholder="DateRange"
              value={momencik(judgment?.nextExamDate, 'YYYY-MM-DD')}
              onChange={e => {
                setJudgment({
                  ...judgment,
                  nextExamDate: e.target.value
                    ? new Date(e.target.value)
                    : null,
                });
              }}
            />
          </FormRow>
          {judgment.article?.pdfTemplate?.type ===
            GetPdfTemplateDtoTypeEnum.WynikWidzeniaZmierzchowego && (
            <>
              <FormRow controlId="twilightVision" label="Widzenie zmierzchowe">
                <Form.Check
                  type="checkbox"
                  defaultChecked={
                    judgment?.twilightVision ||
                    judgment?.twilightVision === undefined ||
                    judgment?.twilightVision === null
                      ? true
                      : false
                  }
                  onChange={e =>
                    setJudgment({
                      ...judgment,
                      twilightVision: e.target.checked,
                    })
                  }
                />
              </FormRow>
              <FormRow
                controlId="glareSensitivity"
                label="Wrażliwość na olśnienie"
              >
                <Form.Check
                  type="checkbox"
                  defaultChecked={
                    judgment?.glareSensitivity ||
                    judgment?.glareSensitivity === undefined ||
                    judgment?.glareSensitivity === null
                      ? true
                      : false
                  }
                  onChange={e =>
                    setJudgment({
                      ...judgment,
                      glareSensitivity: e.target.checked,
                    })
                  }
                />
              </FormRow>
              <FormRow controlId="comment" label="Uwagi">
                <FormControlTextarea3
                  defaultValue={judgment?.comment || ''}
                  onChange={e =>
                    setJudgment({
                      ...judgment,
                      comment: e.target.value,
                    })
                  }
                />
              </FormRow>
            </>
          )}
          {props.mode === ArticleMode.Doctor && (
            <>
              <FormRow
                controlId="vehicleRestriction"
                label="Ograniczenie w zakresie pojazdów"
              >
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={judgment?.vehicleRestriction || ''}
                  onChange={e =>
                    setJudgment({
                      ...judgment,
                      vehicleRestriction: e.target.value,
                    })
                  }
                />
              </FormRow>
              <FormRow
                controlId="personRestriction"
                label="Ograniczenie wobec osoby"
              >
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={judgment?.personRestriction || ''}
                  onChange={e =>
                    setJudgment({
                      ...judgment,
                      personRestriction: e.target.value,
                    })
                  }
                />
              </FormRow>
              <FormRow controlId="roadTransport" label="Transport drogowy">
                <Form.Control
                  as="textarea"
                  rows={1}
                  value={judgment?.roadTransport || ''}
                  onChange={e =>
                    setJudgment({
                      ...judgment,
                      roadTransport: e.target.value,
                    })
                  }
                />
              </FormRow>
            </>
          )}
          {judgment.article?.pdfTemplate?.type ===
            GetPdfTemplateDtoTypeEnum.MpOrzeczenieLekarskie43Transport && (
            <>
              <FormRow controlId="art39j" label="Dopisek o art 39j na wydruku">
                <Form.Check
                  type="checkbox"
                  checked={judgment?.art39j ? true : false}
                  onChange={e =>
                    setJudgment({
                      ...judgment,
                      art39j: e.target.checked,
                    })
                  }
                />
              </FormRow>
            </>
          )}
          <FormRow
            controlId="exam_completed"
            label="Bez podpisu elektronicznego"
          >
            <Form.Check
              type="checkbox"
              checked={judgment?.withoutSign ? true : false}
              onChange={e =>
                setJudgment({
                  ...judgment,
                  withoutSign: e.target.checked,
                })
              }
            />
          </FormRow>
          <FormGroup className="mb-5">
            {judgment?.id && (
              <LastEditorInfo
                className="last-editor-4vh"
                id={judgment?.id}
                method={spd.judgments.getLastEditor}
              />
            )}
          </FormGroup>

          <SaveAndPrintJudgment
            judgment={judgment}
            exam={props.exam}
            onSave={setJudgment}
            setSavingInProgress={setSavingInProgress}
          />
          {judgment?.id && !savingInProgress && (
            <Button
              variant="outline-primary"
              className="ms-1"
              onClick={() => viewDocument()}
            >
              Pokaż orzeczenie
            </Button>
          )}
        </Form>
      </Accordion.Body>
    </Accordion.Item>
  );
};
