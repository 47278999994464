/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateUserDto
 */
export interface CreateUpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateUserDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateUserDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateUserDto
     */
    licenseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateUserDto
     */
    uid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateUserDto
     */
    pin?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateUserDto
     */
    admin?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateUserDto
     */
    active?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateUserDto
     */
    roles?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateUserDto
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateUserDto
     */
    photo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateUserDto
     */
    description?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateUserDto
     */
    healthcareFacilities?: Array<string> | null;
}

export function CreateUpdateUserDtoFromJSON(json: any): CreateUpdateUserDto {
    return CreateUpdateUserDtoFromJSONTyped(json, false);
}

export function CreateUpdateUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'licenseNumber': !exists(json, 'licenseNumber') ? undefined : json['licenseNumber'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'pin': !exists(json, 'pin') ? undefined : json['pin'],
        'admin': !exists(json, 'admin') ? undefined : json['admin'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'photo': !exists(json, 'photo') ? undefined : json['photo'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'healthcareFacilities': !exists(json, 'healthcareFacilities') ? undefined : json['healthcareFacilities'],
    };
}

export function CreateUpdateUserDtoToJSON(value?: CreateUpdateUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'licenseNumber': value.licenseNumber,
        'uid': value.uid,
        'pin': value.pin,
        'admin': value.admin,
        'active': value.active,
        'roles': value.roles,
        'companyId': value.companyId,
        'photo': value.photo,
        'description': value.description,
        'healthcareFacilities': value.healthcareFacilities,
    };
}


