import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  CreateUpdateAppointmentChannelDto,
  CreateUpdateAppointmentChannelDtoFromJSON,
  GetAppointmentChannelDto,
  GetLastEditorDto,
} from '../autogenerated/spdApiClient';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { AppointmentChannelSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import { appointmentChannelForGrid } from '../../../spd/components/AppointmentChannels/AppointmentChannels';

export interface AppointmentChannelContextInterface {
  get: (id: string) => Promise<GetAppointmentChannelDto>;
  getAllCount: (filterText?: string) => Promise<number>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  update: (id: string, dto: CreateUpdateAppointmentChannelDto) => Promise<void>;
  create: (
    dto: CreateUpdateAppointmentChannelDto,
  ) => Promise<GetAppointmentChannelDto>;
  changeToInactive: (id: string) => Promise<void>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const AppointmentChannelContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.appointmentChannelControllerGet(id);
    },

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.appointmentChannelControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(appointmentChannelForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.appointmentChannelControllerGetAllCount(filterText || ''),

    update: (id: string, dto: CreateUpdateAppointmentChannelDto) =>
      api.appointmentChannelControllerUpdate(id, dto),

    create: (dto: CreateUpdateAppointmentChannelDto) =>
      api.appointmentChannelControllerCreate(dto),

    changeToInactive: (id: string) =>
      api.appointmentChannelControllerChangeToInactive(id),

    getLastEditor: (id: string) =>
      api.appointmentChannelControllerGetLastEditor(id),
  };
};

export const validate = (test: CreateUpdateAppointmentChannelDto) =>
  validateAgainst(AppointmentChannelSchema, test);

export const appointmentChannelData = (
  channel?: GetAppointmentChannelDto,
): CreateUpdateAppointmentChannelDto => {
  return CreateUpdateAppointmentChannelDtoFromJSON({
    ...channel,
    ...{ healthcareFacilityId: channel?.healthcareFacility?.id },
  });
};
