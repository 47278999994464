import React from 'react';

import { GetAppointmentChannelDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetAppointmentChannelDto';
import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';

interface AppointmentChannelsProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onClick: (id: string) => void;
  refresh: boolean;
}

export const appointmentChannelForGrid = (
  channel: GetAppointmentChannelDto,
) => {
  return {
    key: channel.id,
    values: {
      'channel.name': channel.name,
      'channel.description': channel.description,
      'channel.healthcareFacility':
        channel.healthcareFacility?.displayName || '',
      'channel.timeWindow': channel.timeWindow,
      'channel.channelPublic': channel.channelPublic ? 'TAK' : 'NIE',
    },
  };
};

export const AppointmentChannels: React.FC<
  AppointmentChannelsProps
> = props => {
  const columns = [
    { header: 'Nazwa', property: 'channel.name' },
    { header: 'Opis', property: 'channel.description' },
    { header: 'Placówka', property: 'channel.healthcareFacility' },
    { header: 'Okno czasowe', property: 'channel.timeWindow' },
    { header: 'Publiczny', property: 'channel.channelPublic' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      onRowClick={props.onClick}
      showFilter={true}
      initialOrderColumn="channel.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak kanałów rezerwacji wizyt w systemie"
      enableMultiSelect={false}
      refresh={props.refresh}
    />
  );
};
