import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import FormRow from '../../../app/components/FormRow';
import { CreateUpdateHarmfulFactorDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/CreateUpdateHarmfulFactorDto';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { useSpdCore } from '../../../common/hooks/useSpdCore';

interface HarmfulFactorEditorProps {
  id?: string;
  harmfulFactor: CreateUpdateHarmfulFactorDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    harmfulFactor: CreateUpdateHarmfulFactorDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const HarmfulFactorEditor: React.FC<
  HarmfulFactorEditorProps
> = props => {
  const [harmfulFactor, setHarmfulFactor] = useState(props.harmfulFactor);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const spd = useSpdCore();

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setHarmfulFactor(props.harmfulFactor);
    }
  }, [props.show, props.harmfulFactor, props.id]);

  const createHarmfulFactor = async () => {
    props
      .onCompleted(harmfulFactor, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
      })
      .catch(error => {
        setSaveErrors(error);
        setShowSaveErrors(true);
      });
  };

  const propertyChange = (obj: Partial<CreateUpdateHarmfulFactorDto>) => {
    setHarmfulFactor({ ...harmfulFactor, ...obj });
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? 'Edycja czynnika szkodliwego'
              : 'Dodawanie nowych czynników szkodliwych'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-2">
            <Card.Header>Czynnik szkodliwy</Card.Header>
            <Card.Body>
              <Form className="d-grid gap-3">
                <FormRow controlId="name" label="Nazwa">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={harmfulFactor?.name || ''}
                    onChange={e => propertyChange({ name: e.target.value })}
                  />
                </FormRow>
                <FormRow controlId="user_active" label="Aktywny">
                  <Form.Check
                    type="checkbox"
                    checked={harmfulFactor?.active ? true : false}
                    onChange={e => propertyChange({ active: e.target.checked })}
                  />
                </FormRow>
              </Form>
            </Card.Body>
          </Card>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="mt-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo
              id={props.id}
              method={spd.harmfulFactors.getLastEditor}
            />
          )}
          <Button variant="outline-secondary" onClick={props.handleClose}>
            Zamknij
          </Button>
          <Button variant="outline-primary" onClick={createHarmfulFactor}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
