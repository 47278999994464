import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { harmfulFactorForGrid } from '../../../spd/components/HarmfulFactor/HarmfulFactorSearch';
import { validateAgainst } from '../validation/validateAgainst';
import {
  CreateUpdateHarmfulFactorDto,
  CreateUpdateHarmfulFactorDtoFromJSON,
} from '../autogenerated/spdApiClient/models/CreateUpdateHarmfulFactorDto';
import { GetHarmfulFactorDto } from '../autogenerated/spdApiClient/models/GetHarmfulFactorDto';
import { HarmfulFactorSchema } from '../validation/schemas';
import { GetLastEditorDto } from '../autogenerated/spdApiClient';

export interface HarmfulFactorContextInterface {
  get: (id: string) => Promise<GetHarmfulFactorDto>;
  getAll: () => Promise<GetHarmfulFactorDto[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  update: (id: string, dto: CreateUpdateHarmfulFactorDto) => Promise<void>;
  create: (dto: CreateUpdateHarmfulFactorDto) => Promise<GetHarmfulFactorDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const HarmfulFactorContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.harmfulFactorControllerGet(id);
    },

    getAll: () => api.harmfulFactorControllerGetAll(),

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.harmfulFactorControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(harmfulFactorForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.harmfulFactorControllerGetAllCount(filterText || ''),

    update: (id: string, dto: CreateUpdateHarmfulFactorDto) =>
      api.harmfulFactorControllerUpdate(id, dto),

    create: (dto: CreateUpdateHarmfulFactorDto) =>
      api.harmfulFactorControllerCreate(dto),

    getLastEditor: (id: string) => api.harmfulFactorControllerGetLastEditor(id),
  };
};

export const validate = (harmfulFactor: CreateUpdateHarmfulFactorDto) => {
  return validateAgainst(HarmfulFactorSchema, harmfulFactor);
};

export const harmfulFactorData = (
  harmfulFactor?: GetHarmfulFactorDto,
): CreateUpdateHarmfulFactorDto => {
  if (!harmfulFactor) {
    return CreateUpdateHarmfulFactorDtoFromJSON({ active: true });
  }
  return CreateUpdateHarmfulFactorDtoFromJSON({
    ...harmfulFactor,
  });
};
