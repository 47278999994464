import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
/* eslint-disable import/named */
import { MultiValue } from 'react-select';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import MultiSelectSortable, {
  ReactSelectOption,
} from '../Selects/SortableSelect';

export interface HealthcareFacilitiesMultiSelectorProps {
  readOnly?: boolean;
  canSeeAll?: boolean;
  selectedHealthcareFacility?: ReactSelectOption[];
  onChange?: (options: MultiValue<ReactSelectOption>) => void;
}

export default (props: HealthcareFacilitiesMultiSelectorProps) => {
  const spd = useSpdCore();
  const [healthcareFacilities, setHealthcareFacilities] = useState<
    ReactSelectOption[]
  >([]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    let mounted = true;
    spd.healthCareFacilities
      .getAll(props.canSeeAll)
      .then(t => {
        if (mounted) {
          const result = t.map(
            a =>
              ({
                value: a.id,
                label: a.displayName,
              } as ReactSelectOption),
          );
          setHealthcareFacilities(result);
          setReady(true);
        }
      })
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem placówek. ${error}`),
      );

    return () => {
      mounted = false;
    };
  }, [spd.healthCareFacilities, props.canSeeAll]);

  return ready ? (
    <MultiSelectSortable
      options={healthcareFacilities}
      defaultValue={props.selectedHealthcareFacility || []}
      onChange={options => props.onChange && props.onChange(options)}
    />
  ) : (
    <Skeleton />
  );
};
