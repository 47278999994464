/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetExamTypeDto,
    GetExamTypeDtoFromJSON,
    GetExamTypeDtoFromJSONTyped,
    GetExamTypeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetExamTypeForChannelDto
 */
export interface GetExamTypeForChannelDto {
    /**
     * 
     * @type {string}
     * @memberof GetExamTypeForChannelDto
     */
    id: string;
    /**
     * 
     * @type {GetExamTypeDto}
     * @memberof GetExamTypeForChannelDto
     */
    examType: GetExamTypeDto;
}

export function GetExamTypeForChannelDtoFromJSON(json: any): GetExamTypeForChannelDto {
    return GetExamTypeForChannelDtoFromJSONTyped(json, false);
}

export function GetExamTypeForChannelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExamTypeForChannelDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'examType': GetExamTypeDtoFromJSON(json['examType']),
    };
}

export function GetExamTypeForChannelDtoToJSON(value?: GetExamTypeForChannelDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'examType': GetExamTypeDtoToJSON(value.examType),
    };
}


