import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  ApiResponse,
  CreateUpdateSavedPdfDto,
  GetSavedPdfExternalReferralDto,
  GetSavedPdfSignatureStatusDto,
} from '../autogenerated/spdApiClient';

export interface SavedPdfContextInterface {
  getSignatureStatusForJudgment: (
    id: string,
  ) => Promise<GetSavedPdfSignatureStatusDto>;
  getSignatureStatusForReferral: (
    id: string,
  ) => Promise<GetSavedPdfSignatureStatusDto>;
  savePdfFile: (attachment: CreateUpdateSavedPdfDto) => Promise<string>;
  savePdfFileForReferral: (
    attachment: CreateUpdateSavedPdfDto,
  ) => Promise<string>;
  getExternalReferralForExamined: (
    examinedId: string,
  ) => Promise<GetSavedPdfExternalReferralDto[]>;
  saveFileInBrowser: (id: string) => Promise<void>;
}

export const SavedPdfContext = (api: DefaultApi) => {
  const handleResponse = async (response: ApiResponse<void>) => {
    const file = await response.raw.blob();
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  return {
    getSignatureStatusForJudgment: (id: string) =>
      api.savedPdfControllerGetJudgmentSignatureStatus(id),
    getSignatureStatusForReferral: (id: string) =>
      api.savedPdfControllerGetReferralSignatureStatus(id),
    savePdfFile: (attachment: CreateUpdateSavedPdfDto) =>
      api.savedPdfControllerSavePdfFile(attachment),
    savePdfFileForReferral: (attachment: CreateUpdateSavedPdfDto) =>
      api.savedPdfControllerSavePdfFileForReferral(attachment),
    getExternalReferralForExamined: (examinedId: string) =>
      api.savedPdfControllerGetExternalReferralForExamined(examinedId),
    saveFileInBrowser: async (id: string) =>
      api.savedPdfControllerGetFileDownloadRaw({ id }).then(handleResponse),
  };
};
