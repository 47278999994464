/*eslint max-lines-per-function: ["error", 174]*/
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import FormRow from '../../../app/components/FormRow';
import { CreateUpdateBatteryDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import MultiSelectSortable, {
  ReactSelectOption,
} from '../Selects/SortableSelect';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { useSpdCore } from '../../../common/hooks/useSpdCore';

interface BatteryEditorProps {
  id?: string;
  battery: CreateUpdateBatteryDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    battery: CreateUpdateBatteryDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  allTests: ReactSelectOption[];
  allArticlesPsychological: ReactSelectOption[];
  allArticlesMedical: ReactSelectOption[];
  selectedTests?: ReactSelectOption[];
  articlesPsychological?: ReactSelectOption[];
  articlesMedical?: ReactSelectOption[];
}

export const BatteryEditor: React.FC<BatteryEditorProps> = props => {
  const [battery, setBattery] = useState(props.battery);
  const [selectedTestIds, setSelectedTestIds] = useState<string[]>([]);
  const [articlesPsychological, setArticlesPsychological] = useState<string[]>(
    [],
  );
  const [articlesMedical, setArticlesMedical] = useState<string[]>([]);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const spd = useSpdCore();

  useEffect(() => {
    const keys = props.selectedTests?.map(o => o.value);
    if (keys) {
      setSelectedTestIds(keys);
    }
  }, [props.selectedTests]);

  useEffect(() => {
    const keys = props.articlesMedical?.map(o => o.value);
    if (keys) {
      setArticlesMedical(keys);
    }
  }, [props.articlesMedical]);

  useEffect(() => {
    const keys = props.articlesPsychological?.map(o => o.value);
    if (keys) {
      setArticlesPsychological(keys);
    }
  }, [props.articlesPsychological]);

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setBattery(props.battery);
    }
  }, [props.show, props.battery, props.id]);

  const createBattery = async () => {
    if (selectedTestIds) {
      battery.tests = selectedTestIds;
    }
    if (articlesPsychological) {
      battery.articlePsychologistsIds = articlesPsychological;
    }
    if (articlesMedical) {
      battery.articleDoctorsIds = articlesMedical;
    }

    props
      .onCompleted(battery, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
      })
      .catch(error => {
        console.error('Error during add battery:', error);
      });
  };

  const propertyChange = (obj: Partial<CreateUpdateBatteryDto>) => {
    setBattery({ ...battery, ...obj });
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? 'Edycja baterii testów'
              : 'Dodawanie nowej baterii testów'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-2">
            <Card.Header>Bateria testów</Card.Header>
            <Card.Body>
              <Form className="d-grid gap-3">
                <FormRow controlId="battery_name" label="Nazwa">
                  <Form.Control
                    type="text"
                    value={battery?.name || ''}
                    onChange={e => propertyChange({ name: e.target.value })}
                  />
                </FormRow>
                <FormRow controlId="battery_description" label="Opis">
                  <Form.Control
                    type="text"
                    value={battery?.description || ''}
                    onChange={e =>
                      propertyChange({ description: e.target.value })
                    }
                  />
                </FormRow>
                <FormRow controlId="battery_tests" label="Wybrane testy">
                  <MultiSelectSortable
                    options={props.allTests}
                    defaultValue={props.selectedTests || []}
                    onChange={options =>
                      setSelectedTestIds((options || []).map(o => o.value))
                    }
                  />
                </FormRow>
                <FormRow
                  controlId="articlesPsychological"
                  label="Podstawy prawne psychologiczne"
                >
                  <Select
                    options={props.allArticlesPsychological}
                    defaultValue={props.articlesPsychological}
                    isMulti
                    className="test-multi-select"
                    classNamePrefix="test-select"
                    onChange={options =>
                      setArticlesPsychological(options.map(o => o.value))
                    }
                  />
                </FormRow>
                <FormRow
                  controlId="articlesMedical"
                  label="Podstawy prawne lekarskie"
                >
                  <Select
                    options={props.allArticlesMedical}
                    defaultValue={props.articlesMedical}
                    isMulti
                    className="test-multi-select"
                    classNamePrefix="test-select"
                    onChange={options =>
                      setArticlesMedical(options.map(o => o.value))
                    }
                  />
                </FormRow>
              </Form>
            </Card.Body>
          </Card>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="mt-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo
              id={props.id}
              method={spd.batteries.getLastEditor}
            />
          )}
          <Button variant="outline-secondary" onClick={props.handleClose}>
            Zamknij
          </Button>
          <Button variant="outline-primary" onClick={createBattery}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
