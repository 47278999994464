import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  CreateUpdateJudgmentDto,
  CreateUpdateJudgmentDtoFromJSON,
} from '../autogenerated/spdApiClient/models/CreateUpdateJudgmentDto';
import { GetJudgmentDto } from '../autogenerated/spdApiClient/models/GetJudgmentDto';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { judgmentForGrid } from '../../../spd/components/Judgments/JudgmentsSearch';
import { GetLastEditorDto } from '../autogenerated/spdApiClient';

export type JudgmentEntity = GetJudgmentDto;

export interface JudgmentContextInterface {
  get: (id: string) => Promise<JudgmentEntity>;
  getForExam: (id: string) => Promise<JudgmentEntity[]>;
  getForRegisterByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    registerId?: string,
    dateFrom?: Date,
    dateTo?: Date,
    userUid?: string,
    healthcareFacilityId?: string,
    setRefresh?: () => void,
  ) => Promise<GridRecord[]>;
  getForRegisterCount: (
    filterText?: string,
    registerId?: string,
    dateFrom?: Date,
    dateTo?: Date,
    userUid?: string,
    healthcareFacilityId?: string,
  ) => Promise<number>;
  getForSigningByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    userUid?: string,
    setRefresh?: () => void,
  ) => Promise<GridRecord[]>;
  getForSigningCount: (
    filterText?: string,
    userUid?: string,
  ) => Promise<number>;
  getWithoutElectronicSigningByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    userUid?: string,
    dateFrom?: Date,
    dateTo?: Date,
    setRefresh?: () => void,
  ) => Promise<GridRecord[]>;
  getWithoutElectronicSigningCount: (
    filterText?: string,
    userUid?: string,
    dateFrom?: Date,
    dateTo?: Date,
  ) => Promise<number>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    setRefresh?: () => void,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  create: (dto: CreateUpdateJudgmentDto) => Promise<GetJudgmentDto>;
  update: (id: string, dto: CreateUpdateJudgmentDto) => Promise<void>;
  changeToInactive: (id: string) => Promise<void>;
  getAllForUserRegisterToSign: (
    userUid: string,
    registerId?: string,
  ) => Promise<GetJudgmentDto[]>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const JudgmentContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.judgmentControllerGet(id);
    },

    getForExam: async (id: string) => {
      return await api.judgmentControllerGetForExam(id);
    },

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      setRefresh?: () => void,
    ) => {
      const data = await api.judgmentControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(judgment => judgmentForGrid(judgment, setRefresh));
    },

    getAllCount: (filterText?: string) =>
      api.judgmentControllerGetAllCount(filterText || ''),

    getForRegisterByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      registerId?: string,
      dateFrom?: Date,
      dateTo?: Date,
      userUid?: string,
      healthcareFacilityId?: string,
      setRefresh?: () => void,
    ) => {
      const data = await api.judgmentControllerGetForRegisterByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        registerId || '',
        dateFrom,
        dateTo,
        userUid,
        healthcareFacilityId,
      );
      return data.map(judgment => judgmentForGrid(judgment, setRefresh));
    },

    getForRegisterCount: (
      filterText?: string,
      registerId?: string,
      dateFrom?: Date,
      dateTo?: Date,
      userUid?: string,
      healthcareFacilityId?: string,
    ) =>
      api.judgmentControllerGetForRegisterCount(
        filterText || '',
        registerId || '',
        dateFrom,
        dateTo,
        userUid,
        healthcareFacilityId,
      ),

    getForSigningByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      userUid?: string,
      setRefresh?: () => void,
    ) => {
      const data = await api.judgmentControllerGetForSigningByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        userUid,
      );
      return data.map(judgment => judgmentForGrid(judgment, setRefresh));
    },

    getForSigningCount: (filterText?: string, userUid?: string) =>
      api.judgmentControllerGetForSigningCount(filterText || '', userUid),

    getWithoutElectronicSigningByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      userUid?: string,
      dateFrom?: Date,
      dateTo?: Date,
      setRefresh?: () => void,
    ) => {
      const data =
        await api.judgmentControllerGetWithoutElectronicSigningByPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
          userUid,
          dateFrom,
          dateTo,
        );
      return data.map(judgment => judgmentForGrid(judgment, setRefresh));
    },
    getWithoutElectronicSigningCount: (
      filterText?: string,
      userUid?: string,
      dateFrom?: Date,
      dateTo?: Date,
    ) => {
      return api.judgmentControllerGetWithoutElectronicSigningCount(
        filterText || '',
        userUid,
        dateFrom,
        dateTo,
      );
    },

    create: (dto: CreateUpdateJudgmentDto) =>
      api.judgmentControllerCreate(dto as GetJudgmentDto),

    update: (id: string, dto: CreateUpdateJudgmentDto) =>
      api.judgmentControllerUpdate(id, dto),

    changeToInactive: (id: string) =>
      api.judgmentControllerChangeToInactive(id),

    getAllForUserRegisterToSign: (userUid: string, registerId?: string) =>
      api.judgmentControllerGetAllForUserRegisterToSign(userUid, registerId),

    getLastEditor: (id: string) => api.judgmentControllerGetLastEditor(id),
  };
};

// export const validate = (judgment: CreateUpdateJudgmentDto) => {
//   return validateAgainst(JudgmentSchema, judgment);
// };

export const judgmentData = (
  judgment?: GetJudgmentDto,
): CreateUpdateJudgmentDto => {
  return CreateUpdateJudgmentDtoFromJSON({
    ...judgment,
    ...{
      psychologistUID: judgment?.psychologist?.uid,
      articleId: judgment?.article?.id,
      examId: judgment?.exam?.id,
    },
  });
};
