import { userForGrid } from '../../../spd/components/Users/Users';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import {
  CreateUpdateUserDto,
  GetUserDto,
  CreateUpdateUserDtoFromJSON,
  GetLastEditorDto,
} from '../autogenerated/spdApiClient';
import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { UserSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export type User = GetUserDto;

export interface UserContextInterface {
  getById: (id: string | null | undefined) => Promise<User | undefined>;
  getByUserUID: (userUID: string | undefined) => Promise<User | undefined>;
  getAllByRoles: (roles: string[]) => Promise<GetUserDto[]>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    mode?: string,
  ) => Promise<GridRecord[]>;
  getByEmail: (email: string | null) => Promise<User | undefined>;
  getAllCount: (filterText?: string, mode?: string) => Promise<number>;
  update: (id: string, dto: CreateUpdateUserDto) => Promise<void>;
  updateGet: (id: string, dto: CreateUpdateUserDto) => Promise<void>;
  create: (dto: CreateUpdateUserDto) => Promise<GetUserDto>;
  updateEmail: (
    id: string | null | undefined,
    email: string,
  ) => Promise<void | undefined>;
  savePin: (id: string | null | undefined, pin: string) => Promise<void>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const UserContext = (api: DefaultApi) => {
  return {
    getById: async (id: string | null | undefined) => {
      if (!id || id === undefined) {
        return;
      }
      return await api.userControllerGet(id);
    },

    getByUserUID: async (userUID: string | undefined) => {
      if (!userUID) {
        return;
      }

      const profs = await api.userControllerGetByUid(userUID);
      if (profs.length === 1) {
        return profs[0] as unknown as Promise<User>;
      }
    },

    getByEmail: async (email: string | null) => {
      if (!email) {
        return;
      }
      const users = await api.userControllerGetByEmail(email);
      if (users.length === 1) {
        return users[0] as unknown as Promise<User>;
      } else if (users.length > 0) {
        throw new Error(
          'Znaleziono więcej niż jednego użytkownika o podanym adresie email',
        );
      } else {
        throw new Error('Nie znaleziono użytkownika o podanym adresie email');
      }
    },

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      mode?: string,
    ) => {
      const data = await api.userControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        mode,
      );
      return data.map(userForGrid);
    },

    getAllCount: (filterText?: string, mode?: string) =>
      api.userControllerGetAllCount(filterText || '', mode),

    getAllByRoles: (roles: string[]) => api.userControllerGetAllByRoles(roles),

    update: (id: string, dto: CreateUpdateUserDto) =>
      api.userControllerUpdate(id, dto),

    updateGet: async (id: string, user: CreateUpdateUserDto) => {
      if (!id) {
        return;
      }

      return api.userControllerRegister(id, CreateUpdateUserDtoFromJSON(user));
    },

    updateEmail: async (id: string | null | undefined, email: string) => {
      if (!id || id === undefined) {
        return;
      }
      return await api.userControllerChangeEmail(id, { email: email });
    },

    create: (dto: CreateUpdateUserDto) => api.userControllerCreate(dto),

    savePin: async (id: string | null | undefined, pin: string) => {
      if (!id || id === undefined) {
        return;
      }
      return await api.userControllerUpdate(id, { pin: pin });
    },

    getLastEditor: (id: string) => api.userControllerGetLastEditor(id),
  };
};

export const userData = (user?: GetUserDto): CreateUpdateUserDto => {
  return CreateUpdateUserDtoFromJSON({
    ...user,
    companyId: user?.company?.id,
    healthcareFacilities: user?.usersInHealthcareFacility
      ?.filter(o => o !== null && o !== undefined)
      ?.map(hf => hf.healthcareFacility),
  });
};

export const validate = (user: CreateUpdateUserDto) =>
  validateAgainst(UserSchema, user);
