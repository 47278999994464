/* eslint-disable complexity */
import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import momencik from '../../../common/momencik';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { ExaminedFromExamPreviewLink } from '../Examined/ExaminedFromExamPreviewLink';
import { GetCardDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import {
  CardType,
  CardTypeText,
} from '../../../common/spdCore/validation/schemas';

import { CardsLink } from './CardsLink';
import { CardPreviewLink } from './CardPreviewLink';

interface CardsSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableSingleSelect?: boolean;
  selectedKeys?: string[];
}

export const cardForGrid = (card: GetCardDto) => {
  return {
    key: card.id,
    values: {
      'exam.number': (
        <>
          {card?.exam?.id ? (
            <CardsLink
              examId={card?.exam?.id}
              type={card.type as unknown as CardType}
            />
          ) : (
            <></>
          )}{' '}
          {card?.exam?.number}
        </>
      ),
      'card.number': card?.number || '',
      'card.cardDate': momencik(card.cardDate),
      'card.postDate': momencik(card.postDate),
      'card.pass': card?.pass ? 'Tak' : ' Nie ',
      'exam.examined': card?.exam?.examined && (
        <>
          <ExaminedFromExamPreviewLink examId={card?.exam?.id} />{' '}
          {card?.exam.examined.surname} {card?.exam.examined.firstname}
        </>
      ),
      'examined.pesel':
        card?.exam?.examined?.pesel ||
        (card?.exam?.examined?.iDCardType || '') +
          ' ' +
          (card?.exam?.examined?.iDCardNumber || '') ||
        '',
      'card.type': card.type && CardTypeText[card.type],
      'card.psychologist': card?.psychologist?.name || '',
      'healthcareFacility.displayName':
        card?.exam?.examPackage?.healthcareFacility?.displayName || '',
      preview: <CardPreviewLink cardId={card.id} />,
    },
  };
};

export const CardsSearch: React.FC<CardsSearchProps> = props => {
  const columns = [
    { header: 'Numer badania', property: 'exam.number' },
    { header: 'Numer karty', property: 'card.number' },
    { header: 'Data karty', property: 'card.cardDate' },
    { header: 'Data wysłania', property: 'card.postDate' },
    { header: 'Wynik pozytywny', property: 'card.pass' },
    { header: 'Badany', property: 'exam.examined' },
    { header: 'PESEL / dokument', property: 'examined.pesel' },
    { header: 'Placówka', property: 'healthcareFacility.displayName' },
    { header: 'Psycholog', property: 'card.psychologist' },
    { header: 'Typ', property: 'card.type', noSorting: true },
    { header: '', property: 'preview', noSorting: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="card.cardDate"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak kart w systemie"
      refresh={props.refresh}
      enableSingleSelect={props.enableSingleSelect}
      selectedKeys={props.selectedKeys}
    />
  );
};
