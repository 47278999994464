/* eslint-disable complexity */
/*eslint max-lines-per-function: ["error", 230]*/
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import { ReactSelectOption } from '../Selects/SortableSelect';
import ValidationAlert from '../../../app/components/ValidationAlert';
import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateUserDto,
  GetHealthcareFacilityDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { useAuth } from '../../../common/hooks/useAuth';
import { RoleText } from '../../../common/spdCore/authorization/roles';
import CompanyByNameSelector from '../Company/CompanyByNameSelector';
import { UploadFile } from '../File/UploadFile';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import HealthcareFacilitiesMultiSelector from '../HealthcareFacility/HealthcareFacilitiesMultiSelector';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { useSpdCore } from '../../../common/hooks/useSpdCore';

interface UserEditorProps {
  id?: string;
  user: CreateUpdateUserDto;
  handleClose?: () => void;
  show?: boolean;
  healthcareFacilities?: (GetHealthcareFacilityDto | null | undefined)[];
  onCompleted: (
    examined: CreateUpdateUserDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const UserEditor: React.FC<UserEditorProps> = props => {
  const [user, setUser] = useState<CreateUpdateUserDto | undefined>(props.user);
  const [adminReadOnly, setAdminReadOnly] = useState(false);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [healthCareFacilities, setHealthcareFacilities] = useState<
    ReactSelectOption[]
  >([]);
  const auth = useAuth();
  const spd = useSpdCore();

  useEffect(() => {
    if (props.id) {
      setAdminReadOnly(auth.userProfile?.id === props.id);
    }
  }, [auth.userProfile?.id, props.id]);

  useEffect(() => {
    if (!props.show) {
      setUser(undefined);
    }
    if (props.show) {
      setUser(props.user);
      setSaveErrors([]);
      setShowSaveErrors(false);
      const fac =
        props.healthcareFacilities
          ?.filter(b => !!b)
          ?.map(b => ({
            value: b?.id || '',
            label: b?.displayName || '',
          })) || [];
      setHealthcareFacilities(fac);
    }
  }, [props.user, props.id, props.show, props.healthcareFacilities]);

  const createUser = async () => {
    if (user) {
      props
        .onCompleted(user, props.id)
        .then(saveStatus => {
          console.log('saveStatus', saveStatus);
          if (!saveStatus.saved) {
            setSaveErrors(saveStatus.errors);
            setShowSaveErrors(true);
            return;
          } else {
            setShowSaveErrors(false);
          }
        })
        .catch(error => {
          setSaveErrors(error);
          setShowSaveErrors(true);
        });
    }
  };

  const propertyChange = (obj: Partial<CreateUpdateUserDto>) => {
    setUser({ ...user, ...obj });
  };

  const getAllRoles = () => {
    const arr = Object.keys(RoleText).map(name => {
      return {
        value: name,
        label: RoleText[name as keyof typeof RoleText],
      };
    });
    return arr;
  };

  const getSelectedRoles = () => {
    if (user) {
      return getAllRoles().filter(item => user.roles?.includes(item.value));
    }
  };

  const handleChangeHF = async (tempHfs: ReactSelectOption[]) => {
    setUser({ ...user, healthcareFacilities: tempHfs.map(o => o.value) });
    setHealthcareFacilities(tempHfs);
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id ? 'Edycja użytkownika' : 'Dodawanie nowego użytkownika'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-2">
            <Card.Header>Użytkownik</Card.Header>
            <Card.Body>
              <Form className="d-grid gap-3">
                <FormRow controlId="user_name" label="Imię i nazwisko">
                  <Form.Control
                    type="text"
                    value={user?.name || ''}
                    onChange={e => propertyChange({ name: e.target.value })}
                  />
                </FormRow>
                <FormRow controlId="user_email" label="Adres e-mail">
                  <Form.Control
                    type="email"
                    value={user?.email || ''}
                    onChange={e => propertyChange({ email: e.target.value })}
                    readOnly={props.id ? true : false}
                  />
                </FormRow>

                <FormRow controlId="user_description" label="Numer uprawnień">
                  <Form.Control
                    type="text"
                    value={user?.licenseNumber || ''}
                    onChange={e =>
                      propertyChange({ licenseNumber: e.target.value })
                    }
                  />
                </FormRow>
                <FormRow controlId="description" label="Opis">
                  <DynamicTextarea
                    value={user?.description || ''}
                    onChange={e =>
                      propertyChange({ description: e.target.value })
                    }
                  />
                </FormRow>
                <FormRow controlId="examined" label="Rola">
                  <Select
                    options={getAllRoles()}
                    value={getSelectedRoles()}
                    isMulti
                    className="test-multi-select"
                    classNamePrefix="driving-select"
                    onChange={options =>
                      propertyChange({ roles: options.map(o => o.value) })
                    }
                  />
                </FormRow>
                <FormRow controlId="company" label="Firma/filia">
                  <CompanyByNameSelector
                    value={user?.companyId}
                    onChange={e => propertyChange({ companyId: e.value })}
                    clearable={true}
                  />
                </FormRow>
                <FormRow
                  controlId="healthcareFacilities"
                  label="Widoczne placówki"
                >
                  <HealthcareFacilitiesMultiSelector
                    selectedHealthcareFacility={healthCareFacilities}
                    canSeeAll={true}
                    onChange={options =>
                      handleChangeHF((options || []).map(o => o))
                    }
                  />
                </FormRow>
                <FormRow controlId="user_admin" label="Administrator">
                  <Form.Check
                    type="checkbox"
                    checked={user?.admin ? true : false}
                    onChange={e => propertyChange({ admin: e.target.checked })}
                    disabled={adminReadOnly}
                  />
                </FormRow>
                <FormRow controlId="user_active" label="Aktywny">
                  <Form.Check
                    type="checkbox"
                    checked={user?.active ? true : false}
                    onChange={e => propertyChange({ active: e.target.checked })}
                  />
                </FormRow>
                <FormRow controlId="photo" label="Zdjęcie" className="mt-3">
                  <UploadFile
                    file={user?.photo || ''}
                    setFile={photo => propertyChange({ photo: photo })}
                    imageHeight={50}
                    buttonText={'Wgraj plik graficzny'}
                  />
                </FormRow>
              </Form>
            </Card.Body>
          </Card>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="mt-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo id={props.id} method={spd.users.getLastEditor} />
          )}
          <Button variant="outline-secondary" onClick={props.handleClose}>
            Zamknij
          </Button>
          <Button variant="outline-primary" onClick={createUser}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
