/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateExamTypeDto
 */
export interface CreateUpdateExamTypeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamTypeDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamTypeDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamTypeDto
     */
    importantNote?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamTypeDto
     */
    popular?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamTypeDto
     */
    price?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamTypeDto
     */
    batteryId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamTypeDto
     */
    categoryId?: string | null;
}

export function CreateUpdateExamTypeDtoFromJSON(json: any): CreateUpdateExamTypeDto {
    return CreateUpdateExamTypeDtoFromJSONTyped(json, false);
}

export function CreateUpdateExamTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateExamTypeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'importantNote': !exists(json, 'importantNote') ? undefined : json['importantNote'],
        'popular': !exists(json, 'popular') ? undefined : json['popular'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'batteryId': !exists(json, 'batteryId') ? undefined : json['batteryId'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
    };
}

export function CreateUpdateExamTypeDtoToJSON(value?: CreateUpdateExamTypeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'importantNote': value.importantNote,
        'popular': value.popular,
        'price': value.price,
        'batteryId': value.batteryId,
        'categoryId': value.categoryId,
    };
}


