import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  CreateUpdateExamTypeCategoryDto,
  CreateUpdateExamTypeCategoryDtoFromJSON,
  GetExamTypeCategoryDto,
  GetLastEditorDto,
} from '../autogenerated/spdApiClient';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { validateAgainst } from '../validation/validateAgainst';
import { ExamTypeCategorySchema } from '../validation/schemas';
import { examTypeCategoryForGrid } from '../../../spd/components/ExamTypeCategory/ExamTypeCategorySearch';

export interface ExamTypeCategoryContextInterface {
  get: (id: string) => Promise<GetExamTypeCategoryDto>;
  getAllCount: (filterText?: string) => Promise<number>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAll: () => Promise<GetExamTypeCategoryDto[]>;
  update: (id: string, dto: CreateUpdateExamTypeCategoryDto) => Promise<void>;
  create: (
    dto: CreateUpdateExamTypeCategoryDto,
  ) => Promise<GetExamTypeCategoryDto>;
  changeToInactive: (id: string) => Promise<void>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const ExamTypeCategoryContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.examTypeCategoryControllerGet(id);
    },

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.examTypeCategoryControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(examTypeCategoryForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.examTypeCategoryControllerGetAllCount(filterText || ''),

    getAll: () => api.examTypeCategoryControllerGetAll(),

    update: (id: string, dto: CreateUpdateExamTypeCategoryDto) =>
      api.examTypeCategoryControllerUpdate(id, dto),

    create: (dto: CreateUpdateExamTypeCategoryDto) =>
      api.examTypeCategoryControllerCreate(dto),

    changeToInactive: (id: string) =>
      api.examTypeCategoryControllerChangeToInactive(id),

    getLastEditor: (id: string) =>
      api.examTypeCategoryControllerGetLastEditor(id),
  };
};

export const validate = (test: CreateUpdateExamTypeCategoryDto) =>
  validateAgainst(ExamTypeCategorySchema, test);

export const examTypeCategoryData = (
  examTypeCategory?: GetExamTypeCategoryDto,
): CreateUpdateExamTypeCategoryDto => {
  return CreateUpdateExamTypeCategoryDtoFromJSON({
    ...examTypeCategory,
  });
};
