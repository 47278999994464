import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default (
  props: React.PropsWithChildren<{
    header: string;
    show?: boolean;
    onOk: () => void;
    onHide?: () => void;
  }>,
) => {
  return (
    <Modal
      show={props.show}
      size="lg"
      centered={true}
      backdrop="static"
      backdropClassName="modal-on-modal-backdrop"
      className="modal-on-modal"
    >
      <Modal.Header>
        <Modal.Title>{props.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.children}
        {props.onHide && (
          <>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                onClick={props.onHide}
                className="mx-1"
                variant="outline-secondary"
              >
                Nie
              </Button>
              <Button onClick={props.onOk} variant="outline-success">
                Tak
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
