import React, { useEffect, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  CreateUpdateAppointmentChannelDto,
  GetAppointmentChannelDto,
  GetHealthcareFacilityDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { validate } from '../../../common/spdCore/contexts/ExamTypeContext';
import { AppointmentChannels } from '../../components/AppointmentChannels/AppointmentChannels';
import { AppointmentChannelEditor } from '../../components/AppointmentChannels/AppointmentChannelEditor';
import { appointmentChannelData } from '../../../common/spdCore/contexts/AppointmentChannelContext';

export const AppointmentChannelsView: React.FC = () => {
  const spd = useSpdCore();

  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [channel, setChannel] = useState<GetAppointmentChannelDto>();
  const [allHealthcareFacilities, setAllHealthcareFacilities] = useState<
    GetHealthcareFacilityDto[]
  >([]);

  useEffect(() => {
    spd.healthCareFacilities
      .getAll()
      .then(hf => setAllHealthcareFacilities(hf))
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem placówek. ${error}`),
      );
  }, [spd.healthCareFacilities]);

  const handleAdd = () => {
    setShow(true);
    setChannel(undefined);
    setRefresh(!refresh);
  };

  const addEditChannel = async (
    etCu: CreateUpdateAppointmentChannelDto,
    id?: string,
  ) => {
    const status = await validate(etCu);
    if (status.valid) {
      try {
        if (id) {
          await spd.appointmentChannels.update(id, etCu);
        } else {
          await spd.appointmentChannels.create(etCu);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const handleClick = async (id: string) => {
    const getChannel = await spd.appointmentChannels.get(id);
    setChannel(getChannel);
    setShow(true);
  };

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="appointmentChannels" className="mb-3">
        <Tab eventKey="search" title="Kanały rezerwacji wizyt" className="m-3">
          <AppointmentChannels
            getData={spd.appointmentChannels.getAllByPortion}
            getCount={spd.appointmentChannels.getAllCount}
            onClick={handleClick}
            refresh={refresh}
          />
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowy kanał rezerwacji wizyt
          </Button>
          <AppointmentChannelEditor
            id={channel?.id}
            channel={appointmentChannelData(channel)}
            examTypes={channel?.examTypesForChannels?.map(cb => cb.examType)}
            onCompleted={addEditChannel}
            show={show}
            handleClose={handleClose}
            healthcareFacilities={allHealthcareFacilities}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
