/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateJudgmentDto
 */
export interface CreateUpdateJudgmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    examId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateJudgmentDto
     */
    nextExamDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateJudgmentDto
     */
    judgmentDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    articleId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    psychologistUID?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateJudgmentDto
     */
    pass?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    occupationalMedicineOneCategory?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    occupationalMedicineText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    vehicleRestriction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    personRestriction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    drivingLicense?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    roadTransport?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateJudgmentDto
     */
    active?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateJudgmentDto
     */
    withoutSign?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateJudgmentDto
     */
    twilightVision?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateJudgmentDto
     */
    glareSensitivity?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    cardId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateJudgmentDto
     */
    art39j?: boolean | null;
}

export function CreateUpdateJudgmentDtoFromJSON(json: any): CreateUpdateJudgmentDto {
    return CreateUpdateJudgmentDtoFromJSONTyped(json, false);
}

export function CreateUpdateJudgmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateJudgmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'examId': !exists(json, 'examId') ? undefined : json['examId'],
        'nextExamDate': !exists(json, 'nextExamDate') ? undefined : (json['nextExamDate'] === null ? null : new Date(json['nextExamDate'])),
        'judgmentDate': !exists(json, 'judgmentDate') ? undefined : (json['judgmentDate'] === null ? null : new Date(json['judgmentDate'])),
        'articleId': !exists(json, 'articleId') ? undefined : json['articleId'],
        'psychologistUID': !exists(json, 'psychologistUID') ? undefined : json['psychologistUID'],
        'pass': !exists(json, 'pass') ? undefined : json['pass'],
        'occupationalMedicineOneCategory': !exists(json, 'occupationalMedicineOneCategory') ? undefined : json['occupationalMedicineOneCategory'],
        'occupationalMedicineText': !exists(json, 'occupationalMedicineText') ? undefined : json['occupationalMedicineText'],
        'vehicleRestriction': !exists(json, 'vehicleRestriction') ? undefined : json['vehicleRestriction'],
        'personRestriction': !exists(json, 'personRestriction') ? undefined : json['personRestriction'],
        'drivingLicense': !exists(json, 'drivingLicense') ? undefined : json['drivingLicense'],
        'roadTransport': !exists(json, 'roadTransport') ? undefined : json['roadTransport'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'withoutSign': !exists(json, 'withoutSign') ? undefined : json['withoutSign'],
        'twilightVision': !exists(json, 'twilightVision') ? undefined : json['twilightVision'],
        'glareSensitivity': !exists(json, 'glareSensitivity') ? undefined : json['glareSensitivity'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'cardId': !exists(json, 'cardId') ? undefined : json['cardId'],
        'art39j': !exists(json, 'art39j') ? undefined : json['art39j'],
    };
}

export function CreateUpdateJudgmentDtoToJSON(value?: CreateUpdateJudgmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'examId': value.examId,
        'nextExamDate': value.nextExamDate === undefined ? undefined : (value.nextExamDate === null ? null : value.nextExamDate.toISOString()),
        'judgmentDate': value.judgmentDate === undefined ? undefined : (value.judgmentDate === null ? null : value.judgmentDate.toISOString()),
        'articleId': value.articleId,
        'psychologistUID': value.psychologistUID,
        'pass': value.pass,
        'occupationalMedicineOneCategory': value.occupationalMedicineOneCategory,
        'occupationalMedicineText': value.occupationalMedicineText,
        'vehicleRestriction': value.vehicleRestriction,
        'personRestriction': value.personRestriction,
        'drivingLicense': value.drivingLicense,
        'roadTransport': value.roadTransport,
        'active': value.active,
        'withoutSign': value.withoutSign,
        'twilightVision': value.twilightVision,
        'glareSensitivity': value.glareSensitivity,
        'comment': value.comment,
        'cardId': value.cardId,
        'art39j': value.art39j,
    };
}


