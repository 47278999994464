import React, { useEffect, useState } from 'react';
import { Form, Card, Button } from 'react-bootstrap';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { CompanySelector } from '../Company/CompanySelector';
import {
  Company,
  companyData,
} from '../../../common/spdCore/contexts/CompanyContext';
import FormRow from '../../../app/components/FormRow';

import { TestGivingProps } from './utilsTestGiving';

interface Props {
  nextStep: () => void;
  handleFormData: (input: string) => (e: string[] | boolean) => void;
  prevStep: () => void;
  values: TestGivingProps;
}

export const ChooseCompany: React.FC<Props> = props => {
  const spd = useSpdCore();

  const [company, setCompany] = useState<Company>(
    props.values.company || companyData(),
  );
  const [sendCompanyEmail, setSendCompanyEmail] = useState<boolean>(false);

  useEffect(() => {
    if (
      props.values.company &&
      'id' in props.values.company &&
      props.values.company?.id
    ) {
      spd.companies
        .get(props.values.company.id)
        .then(comp => setCompany(comp))
        .catch(error => {
          console.log('Error during get company.', error);
        });
    }
  }, [spd.companies, props.values.company]);

  useEffect(() => {
    if (props.values.sendCompanyEmail) {
      setSendCompanyEmail(props.values.sendCompanyEmail);
    }
  }, [spd.companies, props.values.company, props.values.sendCompanyEmail]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitFormData = (e: any) => {
    props.values.company = company;

    e.preventDefault();
    props.nextStep();
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Subtitle className="mb-4">
            Firma, dla której generowany jest pakiet
          </Card.Subtitle>
          <Form onSubmit={submitFormData}>
            <Form.Group className="mb-3">
              <CompanySelector
                onSelect={setCompany}
                value={company}
                readOnly={props.values.company ? true : false}
                onClear={() => {
                  setCompany(companyData());
                }}
              />
              <FormRow
                controlId="sendCompanyEmail"
                label="Wysyłać orzeczenia do firmy?"
              >
                <Form.Check
                  type="checkbox"
                  checked={sendCompanyEmail ? true : false}
                  onChange={e => {
                    props.handleFormData('sendCompanyEmail')(e.target.checked);
                    setSendCompanyEmail(e.target.checked);
                  }}
                />
              </FormRow>
            </Form.Group>
            <div className="d-flex justify-content-end m-3">
              <Button
                variant="outline-secondary"
                className="ms-1"
                onClick={props.prevStep}
              >
                Wstecz
              </Button>

              <Button variant="outline-primary" className="ms-1" type="submit">
                Dalej
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
