/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetExamTypeForChannelDto,
    GetExamTypeForChannelDtoFromJSON,
    GetExamTypeForChannelDtoFromJSONTyped,
    GetExamTypeForChannelDtoToJSON,
    GetHealthcareFacilityDto,
    GetHealthcareFacilityDtoFromJSON,
    GetHealthcareFacilityDtoFromJSONTyped,
    GetHealthcareFacilityDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAppointmentChannelDto
 */
export interface GetAppointmentChannelDto {
    /**
     * 
     * @type {string}
     * @memberof GetAppointmentChannelDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAppointmentChannelDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAppointmentChannelDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAppointmentChannelDto
     */
    timeWindow?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAppointmentChannelDto
     */
    channelPublic?: boolean;
    /**
     * 
     * @type {GetHealthcareFacilityDto}
     * @memberof GetAppointmentChannelDto
     */
    healthcareFacility?: GetHealthcareFacilityDto | null;
    /**
     * 
     * @type {Array<GetExamTypeForChannelDto>}
     * @memberof GetAppointmentChannelDto
     */
    examTypesForChannels?: Array<GetExamTypeForChannelDto> | null;
}

export function GetAppointmentChannelDtoFromJSON(json: any): GetAppointmentChannelDto {
    return GetAppointmentChannelDtoFromJSONTyped(json, false);
}

export function GetAppointmentChannelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAppointmentChannelDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'timeWindow': !exists(json, 'timeWindow') ? undefined : json['timeWindow'],
        'channelPublic': !exists(json, 'channelPublic') ? undefined : json['channelPublic'],
        'healthcareFacility': !exists(json, 'healthcareFacility') ? undefined : GetHealthcareFacilityDtoFromJSON(json['healthcareFacility']),
        'examTypesForChannels': !exists(json, 'examTypesForChannels') ? undefined : (json['examTypesForChannels'] === null ? null : (json['examTypesForChannels'] as Array<any>).map(GetExamTypeForChannelDtoFromJSON)),
    };
}

export function GetAppointmentChannelDtoToJSON(value?: GetAppointmentChannelDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'timeWindow': value.timeWindow,
        'channelPublic': value.channelPublic,
        'healthcareFacility': GetHealthcareFacilityDtoToJSON(value.healthcareFacility),
        'examTypesForChannels': value.examTypesForChannels === undefined ? undefined : (value.examTypesForChannels === null ? null : (value.examTypesForChannels as Array<any>).map(GetExamTypeForChannelDtoToJSON)),
    };
}


