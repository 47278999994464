import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import { Container, Image } from 'react-bootstrap';

import { currentMenuItems } from '../../../common/navigation';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { useAuth } from '../../../common/hooks/useAuth';
import { AuthState } from '../../../common/auth';
import ProfileButton from '../ProfileButton';
import MessageToast from '../MessageToast';
import { useNotifications } from '../../../common/hooks/useNotifications';
import ConnectionIssues from '../ConnectionIssues';

import { MainNavSection } from './MainNavSection';

interface NavMainProps {
  activePage: string;
}

const NavMain: React.FC<NavMainProps> = ({ activePage }) => {
  const productBackground = '#539A61';
  const developBackground = '#4d7298';
  const backgroundColor =
    process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
      ? developBackground
      : productBackground;
  const [message, setMessage] = useState<string>('');
  const spdApi = useSpdCore();
  const authFirebase = useAuth();
  const notifications = useNotifications();

  const [currentLinks, setCurrentLinks] = useState(
    currentMenuItems(
      authFirebase.state === AuthState.LoggedIn,
      authFirebase?.roles,
    ),
  );

  useEffect(() => {
    setCurrentLinks(
      currentMenuItems(
        authFirebase.state === AuthState.LoggedIn,
        authFirebase?.roles,
      ),
    );
    setMessage('');
  }, [authFirebase.state, activePage, authFirebase, spdApi.users]);

  notifications.noBackend = () => {
    setMessage('Brak połączenia z serwerem');
  };

  notifications.unauthorized = () => {
    setMessage('Brak uprawnień do wykonania tej operacji');
  };

  return (
    <>
      <Navbar
        expand="lg"
        collapseOnSelect
        variant="dark"
        role="banner"
        as="header"
        className="mainbar"
        style={{ backgroundColor: backgroundColor }}
      >
        <Container fluid>
          <Navbar.Toggle aria-controls="basic-navbar-nav" label="Menu" />
          <Navbar.Brand href="/" className="me-auto">
            <Image className="img-logo-spd" alt="Logo Spd" />
          </Navbar.Brand>
          <NavbarCollapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {currentLinks.map(({ path, heading, className, submenu, hr }) => {
                return (
                  <MainNavSection
                    className={'mainbarlink ' + className}
                    key={path}
                    heading={heading}
                    path={path}
                    activePage={activePage}
                    items={submenu}
                    hr={hr}
                  />
                );
              })}
            </Nav>
          </NavbarCollapse>
        </Container>
        <ProfileButton activePage={activePage} />
      </Navbar>
      <MessageToast message={message} setMessage={setMessage} />
      <ConnectionIssues />
    </>
  );
};

export default NavMain;
