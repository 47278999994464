/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDutyDto,
    GetDutyDtoFromJSON,
    GetDutyDtoFromJSONTyped,
    GetDutyDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetHealthcareFacilityDto
 */
export interface GetHealthcareFacilityDto {
    /**
     * 
     * @type {string}
     * @memberof GetHealthcareFacilityDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetHealthcareFacilityDto
     */
    nameDoctor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetHealthcareFacilityDto
     */
    namePsychological?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetHealthcareFacilityDto
     */
    displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetHealthcareFacilityDto
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetHealthcareFacilityDto
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetHealthcareFacilityDto
     */
    nip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetHealthcareFacilityDto
     */
    entryContentPsychological?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetHealthcareFacilityDto
     */
    entryContentDoctor?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetHealthcareFacilityDto
     */
    active?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetHealthcareFacilityDto
     */
    main?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetHealthcareFacilityDto
     */
    symbol?: string | null;
    /**
     * 
     * @type {GetDutyDto}
     * @memberof GetHealthcareFacilityDto
     */
    duty?: GetDutyDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetHealthcareFacilityDto
     */
    openTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetHealthcareFacilityDto
     */
    closeTime?: string | null;
}

export function GetHealthcareFacilityDtoFromJSON(json: any): GetHealthcareFacilityDto {
    return GetHealthcareFacilityDtoFromJSONTyped(json, false);
}

export function GetHealthcareFacilityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetHealthcareFacilityDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'nameDoctor': !exists(json, 'nameDoctor') ? undefined : json['nameDoctor'],
        'namePsychological': !exists(json, 'namePsychological') ? undefined : json['namePsychological'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'nip': !exists(json, 'nip') ? undefined : json['nip'],
        'entryContentPsychological': !exists(json, 'entryContentPsychological') ? undefined : json['entryContentPsychological'],
        'entryContentDoctor': !exists(json, 'entryContentDoctor') ? undefined : json['entryContentDoctor'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'main': !exists(json, 'main') ? undefined : json['main'],
        'symbol': !exists(json, 'symbol') ? undefined : json['symbol'],
        'duty': !exists(json, 'duty') ? undefined : GetDutyDtoFromJSON(json['duty']),
        'openTime': !exists(json, 'openTime') ? undefined : json['openTime'],
        'closeTime': !exists(json, 'closeTime') ? undefined : json['closeTime'],
    };
}

export function GetHealthcareFacilityDtoToJSON(value?: GetHealthcareFacilityDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'nameDoctor': value.nameDoctor,
        'namePsychological': value.namePsychological,
        'displayName': value.displayName,
        'city': value.city,
        'address': value.address,
        'nip': value.nip,
        'entryContentPsychological': value.entryContentPsychological,
        'entryContentDoctor': value.entryContentDoctor,
        'active': value.active,
        'main': value.main,
        'symbol': value.symbol,
        'duty': GetDutyDtoToJSON(value.duty),
        'openTime': value.openTime,
        'closeTime': value.closeTime,
    };
}


