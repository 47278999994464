import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import { useNotifications } from '../../../common/hooks/useNotifications';
import momencik from '../../../common/momencik';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { Waiting } from '../../../common/components/Waiting/Waiting';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import YesNoModal from '../../../app/components/YesNoModal';
import WorkDaysCalendar from '../../components/Calendar/WorkDaysCalendar';

export const CalendarView: React.FC = () => {
  const spd = useSpdCore();
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [addDay, setAddDay] = useState<Date | null>();
  const [removeDay, setRemoveDay] = useState<Date | null>();
  const [showRemove, setShowRemove] = useState(false);
  const notifications = useNotifications();
  const [waiting, setWaiting] = useState<boolean>(false);

  useEffect(() => {
    spd.calendars
      .getAllForYear(year)
      .then(days => {
        setSelectedDays(days);
        setAddDay(null);
        setShowAdd(false);
        setRemoveDay(null);
        setShowRemove(false);
      })
      .catch(error => {
        console.log(`Wystąpił problem z kalendarzem. ${error}`);
      });
  }, [spd.calendars, notifications, year, refresh]);

  const onAddDay = (date: Date) => {
    setAddDay(date);
    setShowAdd(true);
  };

  const onRemoveDay = (date: Date) => {
    setRemoveDay(date);
    setShowRemove(true);
  };

  const onAddDayAfterAsk = () => {
    spd.calendars
      .addDay(momencik(addDay, 'YYYY-MM-DD'))
      .then(() => setRefresh(!refresh))
      .catch(error => {
        console.log(`Wystąpił problem z kalendarzem. ${error}`);
      });
  };

  const onRemoveDayAfterAsk = () => {
    spd.calendars
      .removeDay(momencik(removeDay, 'YYYY-MM-DD'))
      .then(() => setRefresh(!refresh))
      .catch(error => {
        console.log(`Wystąpił problem z kalendarzem. ${error}`);
      });
  };

  const createNewYear = async (newYear: number) => {
    setWaiting(true);
    spd.calendars
      .createYear(newYear.toString())
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
      })
      .catch(error => {
        setWaiting(false);
        console.log(`Wystąpił problem z kalendarzem. ${error}`);
      });
  };

  return (
    <>
      <Card className="main-card">
        <Card.Body className="m-3">
          <Card.Title className="pb-3">Kalendarz dni roboczych</Card.Title>

          {waiting && <Waiting />}
          {!waiting && (
            <div className="d-grid gap-2 mb-3">
              <WorkDaysCalendar
                initialSelectedDays={selectedDays}
                year={year}
                setYear={setYear}
                addDay={onAddDay}
                removeDay={onRemoveDay}
              />
              <YesNoModal
                header={'Dodawanie dnia roboczego'}
                show={showAdd}
                onHide={() => setShowAdd(false)}
                onOk={onAddDayAfterAsk}
              >
                Czy na pewno dodać dzień{' '}
                {addDay ? momencik(addDay, 'YYYY-MM-DD') : ''} do kalendarza dni
                roboczych?
              </YesNoModal>
              <YesNoModal
                header={'Usuwanie dnia roboczego'}
                show={showRemove}
                onHide={() => setShowRemove(false)}
                onOk={onRemoveDayAfterAsk}
              >
                Czy na pewno usunąć dzień{' '}
                {removeDay ? momencik(removeDay, 'YYYY-MM-DD') : ''} z
                kalendarza dni roboczych?
              </YesNoModal>
            </div>
          )}

          <ConfirmationButton
            className="me-2"
            modalSize="lg"
            onOK={() => createNewYear(new Date().getFullYear())}
            confirmation={`Czy na pewno chcesz utworzyć rok ${new Date().getFullYear()} w systemie? 
            Uwaga, jeżeli rok jest już utworzony, to zostanie usunięty i utworzony ponownie.`}
            variant="outline-danger"
          >
            Utwórz rok {new Date().getFullYear()}
          </ConfirmationButton>
          <ConfirmationButton
            onOK={() => createNewYear(new Date().getFullYear() + 1)}
            confirmation={`Czy na pewno chcesz utworzyć rok ${
              new Date().getFullYear() + 1
            } w systemie? 
            Uwaga, jeżeli rok jest już utworzony, to zostanie usunięty i utworzony ponownie.`}
            variant="outline-danger"
            modalSize="lg"
          >
            Utwórz rok {new Date().getFullYear() + 1}
          </ConfirmationButton>
        </Card.Body>
      </Card>
    </>
  );
};
