/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetKeyTestDto,
    GetKeyTestDtoFromJSON,
    GetKeyTestDtoFromJSONTyped,
    GetKeyTestDtoToJSON,
    GetTestDto,
    GetTestDtoFromJSON,
    GetTestDtoFromJSONTyped,
    GetTestDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetIndicatorTestDto
 */
export interface GetIndicatorTestDto {
    /**
     * 
     * @type {string}
     * @memberof GetIndicatorTestDto
     */
    id: string;
    /**
     * 
     * @type {GetTestDto}
     * @memberof GetIndicatorTestDto
     */
    test?: GetTestDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetIndicatorTestDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetIndicatorTestDto
     */
    mode?: GetIndicatorTestDtoModeEnum;
    /**
     * 
     * @type {GetKeyTestDto}
     * @memberof GetIndicatorTestDto
     */
    keyTest?: GetKeyTestDto | null;
}

export function GetIndicatorTestDtoFromJSON(json: any): GetIndicatorTestDto {
    return GetIndicatorTestDtoFromJSONTyped(json, false);
}

export function GetIndicatorTestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetIndicatorTestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'test': !exists(json, 'test') ? undefined : GetTestDtoFromJSON(json['test']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'keyTest': !exists(json, 'keyTest') ? undefined : GetKeyTestDtoFromJSON(json['keyTest']),
    };
}

export function GetIndicatorTestDtoToJSON(value?: GetIndicatorTestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'test': GetTestDtoToJSON(value.test),
        'name': value.name,
        'mode': value.mode,
        'keyTest': GetKeyTestDtoToJSON(value.keyTest),
    };
}

/**
* @export
* @enum {string}
*/
export enum GetIndicatorTestDtoModeEnum {
    NumberOfCorrectAnswers = 'NumberOfCorrectAnswers',
    SumOfPresentationTime = 'SumOfPresentationTime',
    WeightedSum = 'WeightedSum'
}


