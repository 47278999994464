/*eslint max-lines-per-function: ["error", 200]*/
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import {
  GetArticleDto,
  GetExamDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import momencik from '../../../common/momencik';
import {
  ArticleMode,
  DrivingLicenseCategory,
  OccupationalMedicineCategory,
} from '../../../common/spdCore/validation/schemas';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { useSpdCore } from '../../../common/hooks/useSpdCore';

interface ExamInfoProps {
  exam: GetExamDto;
  articles: GetArticleDto[];
  mode?: ArticleMode;
}

export const ExamInfo: React.FC<ExamInfoProps> = props => {
  const [selectedArticlesPsychologists, setSelectedArticlesPsychologists] =
    useState<string[]>([]);
  const [selectedArticlesDoctors, setSelectedArticlesDoctors] = useState<
    string[]
  >([]);
  const [selectedDrivingCat, setSelectedDrivingCat] = useState<string[]>([]);
  const [occupMed, setOccupMed] = useState<string[]>([]);

  const spd = useSpdCore();

  useEffect(() => {
    setSelectedArticlesPsychologists(
      props.articles
        .filter(item => props.exam?.articlePsychologistsIds?.includes(item.id))
        .map(article => article.description || ''),
    );
  }, [props.exam?.articlePsychologistsIds, props.articles]);

  useEffect(() => {
    setSelectedArticlesDoctors(
      props.articles
        .filter(item => props.exam?.articleDoctorsIds?.includes(item.id))
        .map(article => article.description || ''),
    );
  }, [props.exam?.articleDoctorsIds, props.articles]);

  useEffect(() => {
    setSelectedDrivingCat(
      Object.keys(DrivingLicenseCategory)
        .filter(item => props.exam?.drivingLicense?.split(',')?.includes(item))
        .map(
          cat =>
            DrivingLicenseCategory[
              cat as keyof typeof DrivingLicenseCategory
            ] || '',
        ),
    );
  }, [props.exam?.drivingLicense]);

  useEffect(() => {
    setOccupMed(
      Object.keys(OccupationalMedicineCategory)
        .filter(item =>
          props.exam?.occupationalMedicineCategory?.includes(item),
        )
        .map(
          occup =>
            OccupationalMedicineCategory[
              occup as keyof typeof OccupationalMedicineCategory
            ] || '',
        ),
    );
  }, [props.exam?.occupationalMedicineCategory]);

  return (
    <>
      <Card className="mb-2">
        <Card.Header>
          <strong>Badanie {props.exam?.number}</strong>
        </Card.Header>
        <Card.Body>
          <Form className="d-grid">
            <Form.Group className="mb-2">
              <Form.Label className="sm-form-label mb-0">Badany</Form.Label>
              <Form.Control
                type="text"
                defaultValue={`${props.exam?.examined?.firstname || ''} ${
                  props.exam?.examined?.surname || ''
                }`}
                readOnly
              />
            </Form.Group>
            {props.mode === ArticleMode.Psychologist && (
              <Form.Group className="mb-2">
                <Form.Label className="sm-form-label mb-0">
                  Psycholog: Podstawa badania
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  defaultValue={selectedArticlesPsychologists.join(', ') || ''}
                  readOnly
                />
              </Form.Group>
            )}
            {props.mode === ArticleMode.Doctor && (
              <Form.Group className="mb-2">
                <Form.Label className="sm-form-label mb-0">
                  Lekarz: Podstawa badania
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  defaultValue={selectedArticlesDoctors.join(', ') || ''}
                  readOnly
                />
              </Form.Group>
            )}
            <Form.Group className="mb-2">
              <Form.Label className="sm-form-label mb-0">
                Dodatkowy opis
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                defaultValue={props.exam?.description || ''}
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="sm-form-label mb-0">
                Kategorie planowane
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={selectedDrivingCat.join(', ') || ''}
                readOnly
              />
            </Form.Group>
            {props.mode === ArticleMode.Psychologist && (
              <Form.Group className="mb-2">
                <Form.Label className="sm-form-label mb-0">
                  Medycyna pracy
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  defaultValue={occupMed.join(', ') || ''}
                  readOnly
                />
              </Form.Group>
            )}
            {props.mode === ArticleMode.Psychologist && (
              <Form.Group className="mb-2">
                <Form.Label className="sm-form-label mb-0">
                  Medycyna pracy - inne
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={1}
                  defaultValue={props.exam?.occupationalMedicineText || ''}
                  readOnly
                />
              </Form.Group>
            )}
            <Form.Group className="mb-2">
              <Form.Label className="sm-form-label mb-0">
                Data rejestracji
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={
                  momencik(
                    props.exam?.registrationDate,
                    'YYYY.MM.DD HH:mm:ss',
                  ) || ''
                }
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="sm-form-label mb-0">
                Data startu
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={
                  momencik(props.exam?.startDate, 'YYYY.MM.DD HH:mm:ss') || ''
                }
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="sm-form-label mb-0">
                Data zakończenia
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={
                  momencik(props.exam?.endDate, 'YYYY.MM.DD HH:mm:ss') || ''
                }
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-4">
              {props.exam.id && (
                <LastEditorInfo
                  id={props.exam.id}
                  method={spd.exams.getLastEditor}
                />
              )}
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
