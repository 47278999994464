import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { GetExamTypeCategoryDto } from '../../../common/spdCore/autogenerated/spdApiClient';

import { Selector, Option } from './Selector';

export interface ExamTypeCategorySelectorProps {
  readOnly?: boolean;
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (option: Option) => void;
}

export const ExamTypeCategorySelector: React.FC<
  ExamTypeCategorySelectorProps
> = props => {
  const spd = useSpdCore();
  const [examTypesCategories, setExamTypesCategories] = useState<
    GetExamTypeCategoryDto[]
  >([]);
  const [displayName, setDisplayName] = useState<string>();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (props.value) {
      spd.examTypesCategories
        .get(props.value || '')
        .then(etc => {
          setDisplayName(etc.name || '');
        })
        .catch(error =>
          console.log(
            `Wystąpił problem z pobraniem kategorii typów badań. ${error}`,
          ),
        );
    }
  }, [props.value, spd.examTypesCategories]);

  useEffect(() => {
    let mounted = true;
    spd.examTypesCategories
      .getAll()
      .then(dt => {
        if (mounted) {
          setExamTypesCategories(dt);
          setReady(true);
        }
      })
      .catch(error =>
        console.log(
          `Wystąpił problem z pobraniem kategorii typów badań. ${error}`,
        ),
      );

    return () => {
      mounted = false;
    };
  }, [spd.examTypesCategories]);

  const provider = async (text: string) => {
    const values = examTypesCategories.filter(v => {
      const name = v.name || '';
      return name.toLocaleLowerCase().includes(text.toLocaleLowerCase());
    });

    return values.map(v => {
      return {
        label: v.name || '',
        value: v.id,
      };
    });
  };

  return ready ? (
    <Selector
      uniqueKey={'etc-' + JSON.stringify(examTypesCategories)}
      className={props.className}
      readOnly={props.readOnly}
      creatable={false}
      placeholder={props.placeholder || 'Wskaż...'}
      provider={provider}
      value={displayName}
      onChange={props.onChange}
    />
  ) : (
    <Skeleton />
  );
};
