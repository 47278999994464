/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetBatteryDto,
    GetBatteryDtoFromJSON,
    GetBatteryDtoFromJSONTyped,
    GetBatteryDtoToJSON,
    GetExamTypeCategoryDto,
    GetExamTypeCategoryDtoFromJSON,
    GetExamTypeCategoryDtoFromJSONTyped,
    GetExamTypeCategoryDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetExamTypeDto
 */
export interface GetExamTypeDto {
    /**
     * 
     * @type {string}
     * @memberof GetExamTypeDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetExamTypeDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamTypeDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamTypeDto
     */
    importantNote?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamTypeDto
     */
    popular?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamTypeDto
     */
    price?: string | null;
    /**
     * 
     * @type {GetBatteryDto}
     * @memberof GetExamTypeDto
     */
    battery?: GetBatteryDto | null;
    /**
     * 
     * @type {GetExamTypeCategoryDto}
     * @memberof GetExamTypeDto
     */
    category?: GetExamTypeCategoryDto | null;
}

export function GetExamTypeDtoFromJSON(json: any): GetExamTypeDto {
    return GetExamTypeDtoFromJSONTyped(json, false);
}

export function GetExamTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExamTypeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'importantNote': !exists(json, 'importantNote') ? undefined : json['importantNote'],
        'popular': !exists(json, 'popular') ? undefined : json['popular'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'battery': !exists(json, 'battery') ? undefined : GetBatteryDtoFromJSON(json['battery']),
        'category': !exists(json, 'category') ? undefined : GetExamTypeCategoryDtoFromJSON(json['category']),
    };
}

export function GetExamTypeDtoToJSON(value?: GetExamTypeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'importantNote': value.importantNote,
        'popular': value.popular,
        'price': value.price,
        'battery': GetBatteryDtoToJSON(value.battery),
        'category': GetExamTypeCategoryDtoToJSON(value.category),
    };
}


