/*eslint complexity: ["error", 15]*/
import React, { useState } from 'react';
import { OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment';

import { GetLastEditorDto } from '../../common/spdCore/autogenerated/spdApiClient';

export default (props: {
  id: string;
  className?: string;
  iconSize?: number;
  placement?: 'left' | 'right' | 'top' | 'bottom';
  method: (id: string) => Promise<GetLastEditorDto>;
  additionalText?: string;
}) => {
  const [lastEditor, setLastEditor] = useState<GetLastEditorDto>();

  const getLastEditor = () => {
    if (!props.id) {
      return;
    }
    setLastEditor(undefined);
    props
      .method(props.id)
      .then(info => {
        setLastEditor(info);
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <OverlayTrigger
      placement={props.placement ?? 'top'}
      onEnter={getLastEditor}
      overlay={
        <Popover id="popover-basic">
          <Popover.Header as="h3" className="text-center">
            Historia
          </Popover.Header>
          <Popover.Body className="text-center">
            {lastEditor ? (
              <>
                {props.additionalText && lastEditor.additionalTs && (
                  <>
                    <h6>{props.additionalText}:</h6>
                    Kto: {lastEditor.additionalOwner} <br />
                    Kiedy:{' '}
                    {lastEditor.additionalTs
                      ? moment(lastEditor.additionalTs).format('LLL')
                      : 'Brak informacji'}
                    <hr />
                  </>
                )}
                <h6>Ostatnia modyfikacja:</h6>
                Kto: {lastEditor.owner || 'Brak informacji'} <br />
                Kiedy:{' '}
                {lastEditor.ts
                  ? moment(lastEditor.ts).format('LLL')
                  : 'Brak informacji'}
                {lastEditor.creator && (
                  <>
                    <hr />
                    <h6>Utworzenie:</h6>
                    Kto: {lastEditor.creator} <br />
                    Kiedy:{' '}
                    {lastEditor.creationTs
                      ? moment(lastEditor.creationTs).format('LLL')
                      : 'Brak informacji'}
                  </>
                )}
              </>
            ) : (
              <Spinner animation="border" variant="secondary" />
            )}
          </Popover.Body>
        </Popover>
      }
    >
      <Icon.InfoCircle
        className={props.className ?? 'last-editor'}
        size={props.iconSize ?? 22}
      />
    </OverlayTrigger>
  );
};
