import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
/* eslint-disable import/named */
import { MultiValue } from 'react-select';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import MultiSelectSortable, {
  ReactSelectOption,
} from '../Selects/SortableSelect';

export interface ExamTypeMultiSelectorProps {
  readOnly?: boolean;
  selectedExamType?: ReactSelectOption[];
  onChange?: (options: MultiValue<ReactSelectOption>) => void;
}

export default (props: ExamTypeMultiSelectorProps) => {
  const spd = useSpdCore();
  const [examTypes, setExamTypes] = useState<ReactSelectOption[]>([]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    let mounted = true;
    spd.examTypes
      .getAll()
      .then(t => {
        if (mounted) {
          const result = t.map(
            a =>
              ({
                value: a.id,
                label: a.name,
              } as ReactSelectOption),
          );
          setExamTypes(result);
          setReady(true);
        }
      })
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem typów badań. ${error}`),
      );

    return () => {
      mounted = false;
    };
  }, [spd.examTypes]);

  return ready ? (
    <MultiSelectSortable
      options={examTypes}
      defaultValue={props.selectedExamType || []}
      onChange={options => props.onChange && props.onChange(options)}
    />
  ) : (
    <Skeleton />
  );
};
