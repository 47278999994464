import React, { useEffect, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  CreateUpdateExamTypeDto,
  GetBatteryDto,
  GetExamTypeDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { ExamTypes } from '../../components/ExamTypes/ExamTypes';
import { ExamTypeEditor } from '../../components/ExamTypes/ExamTypeEditor';
import { examTypeData } from '../../../common/spdCore/contexts/ExamTypeContext';
import { validate } from '../../../common/spdCore/contexts/ExamTypeContext';

export const ExamsTypesView: React.FC = () => {
  const spd = useSpdCore();

  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [examType, setExamType] = useState<GetExamTypeDto>();
  const [allBatteries, setAllBatteries] = useState<GetBatteryDto[]>([]);

  useEffect(() => {
    spd.batteries
      .getAll()
      .then(batteries => setAllBatteries(batteries))
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem baterii. ${error}`),
      );
  }, [spd.batteries]);

  const handleAdd = () => {
    setShow(true);
    setExamType(undefined);
    setRefresh(!refresh);
  };

  const addEditExamType = async (
    etCu: CreateUpdateExamTypeDto,
    id?: string,
  ) => {
    const status = await validate(etCu);
    if (status.valid) {
      try {
        if (id) {
          await spd.examTypes.update(id, etCu);
        } else {
          await spd.examTypes.create(etCu);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const handleClick = async (id: string) => {
    const getExamType = await spd.examTypes.get(id);
    setExamType(getExamType);
    setShow(true);
  };

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="examTypes" className="mb-3">
        <Tab eventKey="search" title="Typy badań" className="m-3">
          <ExamTypes
            getData={spd.examTypes.getAllByPortion}
            getCount={spd.examTypes.getAllCount}
            onClick={handleClick}
            refresh={refresh}
          />
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowy typ badania
          </Button>
          <ExamTypeEditor
            id={examType?.id}
            examType={examTypeData(examType)}
            onCompleted={addEditExamType}
            show={show}
            handleClose={handleClose}
            batteries={allBatteries}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
