import { DefaultApi, GetCalendarDto } from '../autogenerated/spdApiClient';

export interface CalendarContextInterface {
  getAllForYear: (year: string) => Promise<string[]>;
  createYear: (year: string) => Promise<void>;
  addDay: (dailyDate: string) => Promise<GetCalendarDto>;
  removeDay: (dailyDate: string) => Promise<void>;
}

export const CalendarContext = (api: DefaultApi) => {
  return {
    getAllForYear: (year: string) => api.calendarControllerGetAllForYear(year),
    createYear: (year: string) => api.calendarControllerCreateYear(year),
    addDay: (dailyDate: string) => api.calendarControllerAddDay(dailyDate),
    removeDay: (dailyDate: string) =>
      api.calendarControllerRemoveDay(dailyDate),
  };
};
