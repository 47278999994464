import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import momencik from '../../../common/momencik';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetConsultationDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetConsultationDto';
import { ExaminedFromExamPreviewLink } from '../Examined/ExaminedFromExamPreviewLink';
import {
  ConsultationType,
  ConsultationTypeText,
} from '../../../common/spdCore/validation/schemas';

import { ConsultationPreviewLink } from './ConsultationPreviewLink';
import { ConsultationsLink } from './ConsultationsLink';

interface ConsultationsSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableSingleSelect?: boolean;
  selectedKeys?: string[];
}

export const consultationForGrid = (consultation: GetConsultationDto) => {
  return {
    key: consultation.id,
    values: {
      'consultation.number': consultation.number,
      'exam.number': (
        <>
          {consultation?.exam?.id ? (
            <ConsultationsLink
              examId={consultation?.exam?.id}
              type={consultation?.type as unknown as ConsultationType}
            />
          ) : (
            <></>
          )}{' '}
          {consultation?.exam?.number}
        </>
      ),
      'consultation.consultDate': momencik(consultation.consultDate),
      'consultation.type':
        consultation?.type as unknown as ConsultationTypeText,
      'exam.examined': consultation?.exam?.examined && (
        <>
          <ExaminedFromExamPreviewLink examId={consultation?.exam?.id} />{' '}
          {consultation?.exam.examined.surname}{' '}
          {consultation?.exam.examined.firstname}
        </>
      ),
      'examined.pesel':
        consultation?.exam?.examined?.pesel ||
        (consultation?.exam?.examined?.iDCardType || '') +
          ' ' +
          (consultation?.exam?.examined?.iDCardNumber || '') ||
        '',
      'consultation.author': consultation?.author?.name || '',
      'healthcareFacility.displayName':
        consultation?.exam?.examPackage?.healthcareFacility?.displayName || '',

      preview: <ConsultationPreviewLink consultationId={consultation.id} />,
    },
  };
};

export const ConsultationsSearch: React.FC<
  ConsultationsSearchProps
> = props => {
  const columns = [
    { header: 'Numer', property: 'consultation.number' },
    { header: 'Numer badania', property: 'exam.number' },
    { header: 'Data konsultacji', property: 'consultation.consultDate' },
    { header: 'Typ', property: 'consultation.type' },
    { header: 'Badany', property: 'exam.examined' },
    { header: 'PESEL / dokument', property: 'examined.pesel' },
    { header: 'Placówka', property: 'healthcareFacility.displayName' },
    { header: 'Lekarz', property: 'consultation.author' },
    { header: '', property: 'preview', noSorting: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="consultation.consultDate"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak konsultacji w systemie"
      refresh={props.refresh}
      enableSingleSelect={props.enableSingleSelect}
      selectedKeys={props.selectedKeys}
    />
  );
};
