/*eslint max-lines-per-function: ["error", 200]*/
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateExamTypeDto,
  GetBatteryDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { ExamTypeCategorySelector } from '../Selects/ExamTypeCategorySelector';

interface ExamTypeEditorProps {
  id?: string;
  examType: CreateUpdateExamTypeDto;
  handleClose?: () => void;
  show?: boolean;
  batteries: GetBatteryDto[];
  onCompleted: (
    examType: CreateUpdateExamTypeDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const ExamTypeEditor: React.FC<ExamTypeEditorProps> = props => {
  const [examType, setExamType] = useState(props.examType);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const notifications = useNotifications();
  const spd = useSpdCore();

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setExamType(props.examType);
    }
  }, [props.show, props.examType]);

  const createUpdateExamType = async () => {
    props
      .onCompleted(examType, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
      })
      .catch(error => {
        console.error('Error during add exam type:', error);
      });
  };

  const deleteExamType = async () => {
    if (props.id) {
      notifications.onPromise(
        spd.examTypes.changeToInactive(props.id),
        props.handleClose,
      );
    }
  };

  const getAllBatteries = () => {
    const arr = props.batteries.map(reg => {
      return {
        value: reg.id,
        label: reg.name,
      };
    });
    return arr;
  };

  const getSelectedBattery = () => {
    return getAllBatteries().filter(
      item => item.value === props.examType.batteryId,
    );
  };

  const propertyChange = (obj: Partial<CreateUpdateExamTypeDto>) => {
    setExamType({ ...examType, ...obj });
  };

  return (
    <Modal
      onHide={props.handleClose}
      show={props.show}
      centered={true}
      keyboard={true}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.id ? 'Edycja typu badania' : 'Dodawanie nowego typu badania'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-2">
          <Card.Header>Typ badania</Card.Header>
          <Card.Body>
            <Form className="d-grid gap-3">
              <FormRow controlId="category" label="Kategoria typu badania">
                <ExamTypeCategorySelector
                  value={examType?.categoryId || ''}
                  onChange={e => propertyChange({ categoryId: e.value })}
                />
              </FormRow>
              <FormRow controlId="examType_name" label="Nazwa">
                <Form.Control
                  type="text"
                  value={examType?.name || ''}
                  onChange={e => propertyChange({ name: e.target.value })}
                />
              </FormRow>
              <FormRow controlId="examType_description" label="Opis">
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={examType?.description || ''}
                  onChange={e =>
                    propertyChange({ description: e.target.value })
                  }
                />
              </FormRow>
              <FormRow
                controlId="examType_importantNote"
                label="Ważna informacja dla badanego"
              >
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={examType?.importantNote || ''}
                  onChange={e =>
                    propertyChange({ importantNote: e.target.value })
                  }
                />
              </FormRow>
              <FormRow controlId="examType_price" label="Cena">
                <Form.Control
                  type="number"
                  value={examType?.price || 0}
                  onChange={e => propertyChange({ price: e.target.value })}
                />
              </FormRow>
              <FormRow controlId="battery" label="Bateria">
                <Select
                  options={getAllBatteries()}
                  defaultValue={getSelectedBattery()}
                  onChange={options =>
                    propertyChange({ batteryId: options?.value })
                  }
                />
              </FormRow>
              <FormRow controlId="user_popular" label="Popularny">
                <Form.Check
                  type="checkbox"
                  checked={examType?.popular ? true : false}
                  onChange={e => propertyChange({ popular: e.target.checked })}
                />
              </FormRow>

              {/* <FormRow controlId="user_popular" label="Aktywny">
                <Form.Check
                  type="checkbox"
                  readOnly={true}
                  checked={examType?.active ? true : false}
                  onChange={e => propertyChange({ popular: e.target.active })}
                />
              </FormRow> */}
            </Form>
          </Card.Body>
        </Card>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
      </Modal.Body>
      <Modal.Footer>
        {props.id && (
          <LastEditorInfo id={props.id} method={spd.examTypes.getLastEditor} />
        )}
        <Button variant="outline-secondary" onClick={props.handleClose}>
          Zamknij
        </Button>
        {props.id && (
          <ConfirmationButton
            className="mx-1"
            confirmation="Czy na pewno usunąć typ badania?"
            variant="outline-danger"
            onOK={deleteExamType}
          >
            Usuń
          </ConfirmationButton>
        )}
        <Button variant="outline-primary" onClick={createUpdateExamType}>
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
