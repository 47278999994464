/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetArticleDto,
    GetArticleDtoFromJSON,
    GetArticleDtoFromJSONTyped,
    GetArticleDtoToJSON,
    GetCardDto,
    GetCardDtoFromJSON,
    GetCardDtoFromJSONTyped,
    GetCardDtoToJSON,
    GetExamDto,
    GetExamDtoFromJSON,
    GetExamDtoFromJSONTyped,
    GetExamDtoToJSON,
    GetRegisterDto,
    GetRegisterDtoFromJSON,
    GetRegisterDtoFromJSONTyped,
    GetRegisterDtoToJSON,
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoFromJSONTyped,
    GetUserDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetJudgmentDto
 */
export interface GetJudgmentDto {
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    number?: string | null;
    /**
     * 
     * @type {GetExamDto}
     * @memberof GetJudgmentDto
     */
    exam?: GetExamDto | null;
    /**
     * 
     * @type {Date}
     * @memberof GetJudgmentDto
     */
    nextExamDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GetJudgmentDto
     */
    judgmentDate?: Date | null;
    /**
     * 
     * @type {GetArticleDto}
     * @memberof GetJudgmentDto
     */
    article?: GetArticleDto | null;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetJudgmentDto
     */
    psychologist?: GetUserDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetJudgmentDto
     */
    pass?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    occupationalMedicineOneCategory?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    occupationalMedicineText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    vehicleRestriction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    personRestriction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    drivingLicense?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    roadTransport?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetJudgmentDto
     */
    active?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetJudgmentDto
     */
    withoutSign?: boolean | null;
    /**
     * 
     * @type {GetRegisterDto}
     * @memberof GetJudgmentDto
     */
    register?: GetRegisterDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetJudgmentDto
     */
    twilightVision?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetJudgmentDto
     */
    glareSensitivity?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    comment?: string | null;
    /**
     * 
     * @type {GetCardDto}
     * @memberof GetJudgmentDto
     */
    card?: GetCardDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetJudgmentDto
     */
    art39j?: boolean | null;
}

export function GetJudgmentDtoFromJSON(json: any): GetJudgmentDto {
    return GetJudgmentDtoFromJSONTyped(json, false);
}

export function GetJudgmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetJudgmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'exam': !exists(json, 'exam') ? undefined : GetExamDtoFromJSON(json['exam']),
        'nextExamDate': !exists(json, 'nextExamDate') ? undefined : (json['nextExamDate'] === null ? null : new Date(json['nextExamDate'])),
        'judgmentDate': !exists(json, 'judgmentDate') ? undefined : (json['judgmentDate'] === null ? null : new Date(json['judgmentDate'])),
        'article': !exists(json, 'article') ? undefined : GetArticleDtoFromJSON(json['article']),
        'psychologist': !exists(json, 'psychologist') ? undefined : GetUserDtoFromJSON(json['psychologist']),
        'pass': !exists(json, 'pass') ? undefined : json['pass'],
        'occupationalMedicineOneCategory': !exists(json, 'occupationalMedicineOneCategory') ? undefined : json['occupationalMedicineOneCategory'],
        'occupationalMedicineText': !exists(json, 'occupationalMedicineText') ? undefined : json['occupationalMedicineText'],
        'vehicleRestriction': !exists(json, 'vehicleRestriction') ? undefined : json['vehicleRestriction'],
        'personRestriction': !exists(json, 'personRestriction') ? undefined : json['personRestriction'],
        'drivingLicense': !exists(json, 'drivingLicense') ? undefined : json['drivingLicense'],
        'roadTransport': !exists(json, 'roadTransport') ? undefined : json['roadTransport'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'withoutSign': !exists(json, 'withoutSign') ? undefined : json['withoutSign'],
        'register': !exists(json, 'register') ? undefined : GetRegisterDtoFromJSON(json['register']),
        'twilightVision': !exists(json, 'twilightVision') ? undefined : json['twilightVision'],
        'glareSensitivity': !exists(json, 'glareSensitivity') ? undefined : json['glareSensitivity'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'card': !exists(json, 'card') ? undefined : GetCardDtoFromJSON(json['card']),
        'art39j': !exists(json, 'art39j') ? undefined : json['art39j'],
    };
}

export function GetJudgmentDtoToJSON(value?: GetJudgmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'exam': GetExamDtoToJSON(value.exam),
        'nextExamDate': value.nextExamDate === undefined ? undefined : (value.nextExamDate === null ? null : value.nextExamDate.toISOString()),
        'judgmentDate': value.judgmentDate === undefined ? undefined : (value.judgmentDate === null ? null : value.judgmentDate.toISOString()),
        'article': GetArticleDtoToJSON(value.article),
        'psychologist': GetUserDtoToJSON(value.psychologist),
        'pass': value.pass,
        'occupationalMedicineOneCategory': value.occupationalMedicineOneCategory,
        'occupationalMedicineText': value.occupationalMedicineText,
        'vehicleRestriction': value.vehicleRestriction,
        'personRestriction': value.personRestriction,
        'drivingLicense': value.drivingLicense,
        'roadTransport': value.roadTransport,
        'active': value.active,
        'withoutSign': value.withoutSign,
        'register': GetRegisterDtoToJSON(value.register),
        'twilightVision': value.twilightVision,
        'glareSensitivity': value.glareSensitivity,
        'comment': value.comment,
        'card': GetCardDtoToJSON(value.card),
        'art39j': value.art39j,
    };
}


