/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetExamTypeCategoryDto
 */
export interface GetExamTypeCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof GetExamTypeCategoryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetExamTypeCategoryDto
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamTypeCategoryDto
     */
    active: boolean;
}

export function GetExamTypeCategoryDtoFromJSON(json: any): GetExamTypeCategoryDto {
    return GetExamTypeCategoryDtoFromJSONTyped(json, false);
}

export function GetExamTypeCategoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExamTypeCategoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'active': json['active'],
    };
}

export function GetExamTypeCategoryDtoToJSON(value?: GetExamTypeCategoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'active': value.active,
    };
}


