import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetHealthcareFacilityDto } from '../../../common/spdCore/autogenerated/spdApiClient';

interface HealthcareFacilitiesSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const healthcareFacilityForGrid = (
  healthcareFacility: GetHealthcareFacilityDto,
) => {
  return {
    key: healthcareFacility.id,
    values: {
      'healthcareFacility.nameDoctor': healthcareFacility.nameDoctor,
      'healthcareFacility.namePsychological':
        healthcareFacility.namePsychological,
      'healthcareFacility.displayName': healthcareFacility.displayName,
      'healthcareFacility.city': healthcareFacility.city,
      'healthcareFacility.address': healthcareFacility.address,
      'healthcareFacility.nip': healthcareFacility.nip,
      'healthcareFacility.entryContentPsychological':
        healthcareFacility.entryContentPsychological,
      'healthcareFacility.entryContentDoctor':
        healthcareFacility.entryContentDoctor,
      'healthcareFacility.symbol': healthcareFacility.symbol,
      'healthcareFacility.main': healthcareFacility.main ? 'TAK' : '',
      'healthcareFacility.active': healthcareFacility.active ? 'TAK' : '',
      'duty.name': healthcareFacility.duty?.name,
      'healthcareFacility.time':
        (healthcareFacility.openTime?.slice(0, 5) || '') +
        '  -  ' +
        (healthcareFacility.closeTime?.slice(0, 5) || ''),
    },
  };
};

export const HealthcareFacilitySearch: React.FC<
  HealthcareFacilitiesSearchProps
> = props => {
  const columns = [
    {
      header: 'Nazwa placówki lekarskiej',
      property: 'healthcareFacility.nameDoctor',
    },
    {
      header: 'Nazwa placówki psychologicznej',
      property: 'healthcareFacility.namePsychological',
    },
    {
      header: 'Nazwa wyświetlana',
      property: 'healthcareFacility.displayName',
    },
    { header: 'Adres', property: 'healthcareFacility.address' },
    { header: 'NIP/REGON', property: 'healthcareFacility.nip' },
    {
      header: 'Wpis (psycholog)',
      property: 'healthcareFacility.entryContentPsychological',
    },
    {
      header: 'Wpis (lekarz)',
      property: 'healthcareFacility.entryContentDoctor',
    },
    { header: 'Symbol', property: 'healthcareFacility.symbol' },
    { header: 'Aktywna', property: 'healthcareFacility.active' },
    { header: 'Dyżur', property: 'duty.name' },
    {
      header: 'Godziny pracy',
      property: 'healthcareFacility.time',
      noSorting: true,
    },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="healthcareFacility.displayName"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak placówek w systemie"
      enableMultiSelect={false}
      refresh={props.refresh}
    />
  );
};
