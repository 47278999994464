/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateAppointmentChannelDto
 */
export interface CreateUpdateAppointmentChannelDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAppointmentChannelDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAppointmentChannelDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAppointmentChannelDto
     */
    timeWindow?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateAppointmentChannelDto
     */
    channelPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAppointmentChannelDto
     */
    healthcareFacilityId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateAppointmentChannelDto
     */
    examTypes?: Array<string> | null;
}

export function CreateUpdateAppointmentChannelDtoFromJSON(json: any): CreateUpdateAppointmentChannelDto {
    return CreateUpdateAppointmentChannelDtoFromJSONTyped(json, false);
}

export function CreateUpdateAppointmentChannelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateAppointmentChannelDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'timeWindow': !exists(json, 'timeWindow') ? undefined : json['timeWindow'],
        'channelPublic': !exists(json, 'channelPublic') ? undefined : json['channelPublic'],
        'healthcareFacilityId': !exists(json, 'healthcareFacilityId') ? undefined : json['healthcareFacilityId'],
        'examTypes': !exists(json, 'examTypes') ? undefined : json['examTypes'],
    };
}

export function CreateUpdateAppointmentChannelDtoToJSON(value?: CreateUpdateAppointmentChannelDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'timeWindow': value.timeWindow,
        'channelPublic': value.channelPublic,
        'healthcareFacilityId': value.healthcareFacilityId,
        'examTypes': value.examTypes,
    };
}


