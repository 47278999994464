import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  CreateUpdateExamTypeDto,
  CreateUpdateExamTypeDtoFromJSON,
  GetExamTypeDto,
  GetLastEditorDto,
} from '../autogenerated/spdApiClient';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { examTypeForGrid } from '../../../spd/components/ExamTypes/ExamTypes';
import { validateAgainst } from '../validation/validateAgainst';
import { ExamTypeSchema } from '../validation/schemas';

export interface ExamTypeContextInterface {
  get: (id: string) => Promise<GetExamTypeDto>;
  getAllCount: (filterText?: string) => Promise<number>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAll: () => Promise<GetExamTypeDto[]>;
  update: (id: string, dto: CreateUpdateExamTypeDto) => Promise<void>;
  create: (dto: CreateUpdateExamTypeDto) => Promise<GetExamTypeDto>;
  changeToInactive: (id: string) => Promise<void>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const ExamTypeContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.examTypeControllerGet(id);
    },

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.examTypeControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(examTypeForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.examTypeControllerGetAllCount(filterText || ''),

    getAll: () => api.examTypeControllerGetAll(),

    update: (id: string, dto: CreateUpdateExamTypeDto) =>
      api.examTypeControllerUpdate(id, dto),

    create: (dto: CreateUpdateExamTypeDto) => api.examTypeControllerCreate(dto),

    changeToInactive: (id: string) =>
      api.examTypeControllerChangeToInactive(id),

    getLastEditor: (id: string) => api.examTypeControllerGetLastEditor(id),
  };
};

export const validate = (test: CreateUpdateExamTypeDto) =>
  validateAgainst(ExamTypeSchema, test);

export const examTypeData = (
  examType?: GetExamTypeDto,
): CreateUpdateExamTypeDto => {
  return CreateUpdateExamTypeDtoFromJSON({
    ...examType,
    ...{ batteryId: examType?.battery?.id, categoryId: examType?.category?.id },
  });
};
