/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetCompanyDto,
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
    GetUserInHealthcareFacilityDto,
    GetUserInHealthcareFacilityDtoFromJSON,
    GetUserInHealthcareFacilityDtoFromJSONTyped,
    GetUserInHealthcareFacilityDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetUserDto
 */
export interface GetUserDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    licenseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    uid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    pin?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserDto
     */
    admin?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserDto
     */
    active?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserDto
     */
    roles?: Array<string> | null;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetUserDto
     */
    company?: GetCompanyDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    photo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    description?: string | null;
    /**
     * 
     * @type {Array<GetUserInHealthcareFacilityDto>}
     * @memberof GetUserDto
     */
    usersInHealthcareFacility?: Array<GetUserInHealthcareFacilityDto> | null;
}

export function GetUserDtoFromJSON(json: any): GetUserDto {
    return GetUserDtoFromJSONTyped(json, false);
}

export function GetUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'licenseNumber': !exists(json, 'licenseNumber') ? undefined : json['licenseNumber'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'pin': !exists(json, 'pin') ? undefined : json['pin'],
        'admin': !exists(json, 'admin') ? undefined : json['admin'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'company': !exists(json, 'company') ? undefined : GetCompanyDtoFromJSON(json['company']),
        'photo': !exists(json, 'photo') ? undefined : json['photo'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'usersInHealthcareFacility': !exists(json, 'usersInHealthcareFacility') ? undefined : (json['usersInHealthcareFacility'] === null ? null : (json['usersInHealthcareFacility'] as Array<any>).map(GetUserInHealthcareFacilityDtoFromJSON)),
    };
}

export function GetUserDtoToJSON(value?: GetUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'licenseNumber': value.licenseNumber,
        'uid': value.uid,
        'pin': value.pin,
        'admin': value.admin,
        'active': value.active,
        'roles': value.roles,
        'company': GetCompanyDtoToJSON(value.company),
        'photo': value.photo,
        'description': value.description,
        'usersInHealthcareFacility': value.usersInHealthcareFacility === undefined ? undefined : (value.usersInHealthcareFacility === null ? null : (value.usersInHealthcareFacility as Array<any>).map(GetUserInHealthcareFacilityDtoToJSON)),
    };
}


