import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Card, Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateExamsPackageDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/CreateUpdateExamsPackageDto';
import FormRow from '../../../app/components/FormRow';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { useSpdCore } from '../../../common/hooks/useSpdCore';

interface ExamPackageEditorProps {
  id: string;
  examsPackage: CreateUpdateExamsPackageDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted?: (
    examsPackage: CreateUpdateExamsPackageDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  readOnly: boolean;
}

export const ExamPackageEditor: React.FC<ExamPackageEditorProps> = props => {
  const [examsPackage, setExamsPackage] = useState(props.examsPackage);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const spd = useSpdCore();

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setExamsPackage(props.examsPackage);
    }
  }, [props.show, props.examsPackage]);

  const createExamsPackage = async () => {
    if (props.onCompleted) {
      props
        .onCompleted(examsPackage, props.id)
        .then(saveStatus => {
          if (!saveStatus.saved) {
            setSaveErrors(saveStatus.errors);
            setShowSaveErrors(true);
            return;
          }
        })
        .catch(error => {
          console.error('Error during add examsPackage:', error);
        });
    }
  };

  const propertyChange = (obj: Partial<CreateUpdateExamsPackageDto>) => {
    setExamsPackage({ ...examsPackage, ...obj });
  };

  const setTitle = () => {
    return (
      props.id &&
      (props.onCompleted ? 'Edycja paczki ' : 'Podgląd paczki') +
        (examsPackage?.number || '')
    );
  };
  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="xl"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick={(event: any) => event.stopPropagation()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{setTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-2">
            <Card.Header>Firma</Card.Header>
            <Card.Body>
              <Form className="d-grid gap-3">
                <FormRow controlId="examsPackageName" label="Opis">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={examsPackage?.description || ''}
                    onChange={e =>
                      propertyChange({ description: e.target.value })
                    }
                    disabled={props.readOnly}
                  />
                </FormRow>
                <FormRow controlId="examsPackageName" label="Opis">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={examsPackage?.description || ''}
                    onChange={e =>
                      propertyChange({ description: e.target.value })
                    }
                    disabled={props.readOnly}
                  />
                </FormRow>
                <FormRow
                  controlId="examsPackageSendMail"
                  label="Wysyłać orzeczenia do firmy?"
                >
                  <Form.Check
                    type="checkbox"
                    defaultChecked={
                      examsPackage?.sendCompanyEmail ? true : false
                    }
                    onChange={e =>
                      propertyChange({ sendCompanyEmail: e.target.checked })
                    }
                  />
                </FormRow>
              </Form>
            </Card.Body>
          </Card>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="mt-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo
              id={props.id}
              method={spd.examsPackage.getLastEditor}
            />
          )}
          <Button variant="outline-secondary" onClick={props.handleClose}>
            Zamknij
          </Button>
          {props.onCompleted && (
            <Button variant="outline-primary" onClick={createExamsPackage}>
              Zapisz
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
