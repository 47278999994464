/* eslint-disable complexity */
/*eslint max-lines-per-function: ["error", 200]*/
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  CreateUpdateCardDto,
  GetArticleDto,
  GetCardDto,
  GetExamDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import {
  ArticleMode,
  CardType,
  TemplateType,
} from '../../../common/spdCore/validation/schemas';
import { CardPreventiveEditor } from '../../components/ExamCards/CardPreventiveEditor';
import { CardPsychologicalEditor } from '../../components/ExamCards/CardPsychologicalEditor';
import { ExamInfo } from '../../components/ExamJudgments/ExamInfo';
import { CardDriverEditor } from '../../components/ExamCards/CardDriverEditor';
import { CardSanitaryEditor } from '../../components/ExamCards/CardSanitaryEditor';
import { useNavigation } from '../../../common/navigation';
import { CardSportEditor } from '../../components/ExamCards/CardSportEditor';
import { CardWeaponEditor } from '../../components/ExamCards/CardWeaponEditor';
import { useSzafir } from '../../../common/hooks/useSzafir';
import { useAuth } from '../../../common/hooks/useAuth';

type ParamTypes = {
  type: TemplateType;
  id: string;
};

export const ExamCardsView: React.FC = () => {
  const spd = useSpdCore();
  const nav = useNavigation();
  const szafir = useSzafir();
  const auth = useAuth();
  const [exam, setExam] = useState<GetExamDto | null | undefined>();
  const [card, setCard] = useState<GetCardDto | undefined>();
  const [newCard, setNewCard] = useState<CreateUpdateCardDto | undefined>();
  const [allArticles, setAllArticles] = useState<GetArticleDto[]>([]);
  const [judgmentsPsychologistName, setJudgmentsPsychologistName] =
    useState<string>('Orzeczenia psychologiczne');
  const [judgmentsDoctorName, setJudgmentsDoctorName] = useState<string>(
    'Orzeczenia lekarskie',
  );

  useEffect(() => {
    if (auth.canSign) {
      szafir.activate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.canSign]);

  const { type, id } = useParams<ParamTypes>() as {
    type: TemplateType;
    id: string;
  };

  useEffect(() => {
    let mounted = true;
    const cardType =
      type === TemplateType.Psychological_Exam_card
        ? CardType.Psychologist
        : type === TemplateType.Weapon_psychological_exam_card
        ? CardType.PsychologistWeapon
        : type === TemplateType.Preventive_Exam_card
        ? CardType.Preventive
        : type === TemplateType.Sanitary_Exam_card
        ? CardType.Sanitary
        : type === TemplateType.Sport_card
        ? CardType.Sport
        : type === TemplateType.Weapon_card
        ? CardType.Weapon
        : CardType.Driver;

    spd.cards
      .getCardByExam(id, cardType)
      .then(res => {
        if (mounted) {
          setCard(res);
          setExam(res.exam);
          spd.cards
            .cardData(id, type, res)
            .then(r => setNewCard(r))
            .catch(error =>
              console.log(`Wystąpił problem z pobraniem karty. ${error}`),
            );
        }
      })
      .catch(() => {
        spd.exams
          .getExam(id)
          .then(res => {
            if (mounted) {
              setExam(res);
              setCard(undefined);
              spd.cards
                .cardData(id, type, undefined)
                .then(r => setNewCard(r))
                .catch(error =>
                  console.log(`Wystąpił problem z pobraniem karty. ${error}`),
                );
            }
          })
          .catch(error =>
            console.log(`Wystąpił problem z odświeżeniem strony. ${error}`),
          );
      });

    return () => {
      mounted = false;
    };
  }, [id, spd.cards, spd.exams, type]);

  useEffect(() => {
    spd.articles
      .getAll()
      .then(article => setAllArticles(article))
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem podstaw prawnych. ${error}`),
      );
  }, [spd.articles]);

  useEffect(() => {
    if (id) {
      spd.judgments
        .getForExam(id)
        .then(o => {
          const judPsychoCount = o.filter(
            j =>
              (j?.article?.mode as unknown as ArticleMode) ===
              ArticleMode.Psychologist,
          ).length;
          const judDoctorCount = o.length - judPsychoCount;
          setJudgmentsPsychologistName(
            `Orzeczenia psychologiczne 
      ${(judPsychoCount > 0 && `(${judPsychoCount})`) || ''}`,
          );
          setJudgmentsDoctorName(`Orzeczenia lekarskie 
    ${(judDoctorCount > 0 && `(${judDoctorCount})`) || ''}`);
        })
        .catch(() => console.log('Nie udało się pobrać orzeczeń'));
    }
  }, [spd.judgments, id]);

  if (!newCard) {
    return null;
  }
  return (
    <>
      {exam && (
        <Row>
          <Col className="col-lg-4 col-12 mb-1">
            <ExamInfo
              exam={exam}
              articles={allArticles}
              mode={
                type === TemplateType.Psychological_Exam_card ||
                type === TemplateType.Weapon_psychological_exam_card
                  ? ArticleMode.Psychologist
                  : ArticleMode.Doctor
              }
            />
            {(type === TemplateType.Psychological_Exam_card ||
              type === TemplateType.Weapon_psychological_exam_card) && (
              <Button
                onClick={e => {
                  e.stopPropagation();
                  nav.examPsychologistJudgments(id);
                }}
                variant="outline-secondary"
                className="ms-1 mb-1"
              >
                {judgmentsPsychologistName}
              </Button>
            )}
            {type !== TemplateType.Psychological_Exam_card &&
              type !== TemplateType.Weapon_psychological_exam_card && (
                <Button
                  onClick={e => {
                    e.stopPropagation();
                    nav.examDoctorJudgments(id);
                  }}
                  variant="outline-secondary"
                  className="ms-1 mb-1"
                >
                  {judgmentsDoctorName}
                </Button>
              )}
          </Col>
          <Col className="col-lg-8 col-12 mb-1">
            {type === TemplateType.Psychological_Exam_card && (
              <CardPsychologicalEditor
                exam={exam}
                card={newCard}
                id={card?.id}
                weapon={false}
              />
            )}
            {type === TemplateType.Weapon_psychological_exam_card && (
              <CardPsychologicalEditor
                exam={exam}
                card={newCard}
                id={card?.id}
                weapon={true}
              />
            )}
            {type === TemplateType.Weapon_card && (
              <CardWeaponEditor exam={exam} card={newCard} id={card?.id} />
            )}
            {type === TemplateType.Preventive_Exam_card && (
              <CardPreventiveEditor exam={exam} card={newCard} id={card?.id} />
            )}
            {type === TemplateType.Driver_Exam_card && (
              <CardDriverEditor exam={exam} card={newCard} id={card?.id} />
            )}
            {type === TemplateType.Sanitary_Exam_card && (
              <CardSanitaryEditor exam={exam} card={newCard} id={card?.id} />
            )}
            {type === TemplateType.Sport_card && (
              <CardSportEditor exam={exam} card={newCard} id={card?.id} />
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
