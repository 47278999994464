import { Buffer } from 'buffer';

import { saveAs } from 'file-saver';

import { validateAgainst } from '../validation/validateAgainst';
//import * as mime from 'mime-types';
import {
  AttachmentFileContent,
  CreateUpdateAttachmentDto,
  CreateUpdateAttachmentDtoFromJSON,
  DefaultApi,
  GetAttachmentDto,
  GetLastEditorDto,
} from '../autogenerated/spdApiClient';
import { AttachmentSchema } from '../validation/schemas';

export interface AttachmentContextInterface {
  getById: (id: string) => Promise<GetAttachmentDto>;
  getAllForExamined: (examinedId: string) => Promise<GetAttachmentDto[]>;
  getFile: (id: string) => Promise<AttachmentFileContent>;
  saveFileInBrowser: (
    id: string,
    name: string,
    method: 'download' | 'open' | string,
  ) => void;
  create: (attachment: CreateUpdateAttachmentDto) => Promise<GetAttachmentDto>;
  update: (id: string, attachment: CreateUpdateAttachmentDto) => Promise<void>;
  createVoid: (attachment: CreateUpdateAttachmentDto) => Promise<void>;
  delete: (id: string) => Promise<void>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export type Attachment = GetAttachmentDto | CreateUpdateAttachmentDto;

export const newAttachment = () => {
  return CreateUpdateAttachmentDtoFromJSON({});
};

export const AttachmentContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.attachmentControllerGet(id),
    getAllForExamined: (examinedId: string) =>
      api.attachmentControllerGetAllForExamined(examinedId),

    getFile: (id: string) => api.attachmentControllerGetFileDownload(id),
    create: (attachment: CreateUpdateAttachmentDto) =>
      api.attachmentControllerCreate(attachment),
    createVoid: async (attachment: CreateUpdateAttachmentDto) => {
      await api.attachmentControllerCreate(attachment);
    },
    update: (id: string, attachment: CreateUpdateAttachmentDto) =>
      api.attachmentControllerUpdate(id, attachment),
    delete: (id: string) => api.attachmentControllerDelete(id),
    saveFileInBrowser: (
      id: string,
      name: string,
      method: 'download' | 'open' | string,
    ) => {
      api
        .attachmentControllerGetFileDownload(id)
        .then(fileContent => {
          const blob = new Blob(
            [Buffer.from(fileContent.buffer as ArrayBuffer)],
            // {
            //   type: mime.lookup(name) || undefined,
            // },
          );
          if (method === 'open') {
            window.open(URL.createObjectURL(blob));
          } else {
            saveAs(blob, name);
          }
        })
        .catch(error => {
          console.error('Error during saveFileInBrowser:', error);
        });
    },
    getLastEditor: (id: string) => api.attachmentControllerGetLastEditor(id),
  };
};

export const validate = (attachment: CreateUpdateAttachmentDto) =>
  validateAgainst(AttachmentSchema, attachment);

export const attachmentData = (
  attach?: GetAttachmentDto,
  examinedId?: string,
): CreateUpdateAttachmentDto => {
  if (attach) {
    return CreateUpdateAttachmentDtoFromJSON({
      ...attach,
      ...{
        examinedId: attach.examined?.id,
        examId: attach.exam?.id,
        testId: attach.test?.id,
      },
    });
  } else {
    return CreateUpdateAttachmentDtoFromJSON({
      ...{ examinedId: examinedId, date: new Date() },
    });
  }
};
