/* eslint-disable complexity */
import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import momencik from '../../../common/momencik';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { ExaminedFromExamPreviewLink } from '../Examined/ExaminedFromExamPreviewLink';
import {
  ArticleMode,
  EmailStatus,
  RegisterMode,
} from '../../../common/spdCore/validation/schemas';
import { GetJudgmentExtendedDto } from '../../../common/spdCore/autogenerated/spdApiClient/models';
import { EmailStatusIcon } from '../Emails/EmailStatusIcon';
import { EmailSendLink } from '../Emails/EmailSendLink';

import { JudgmentPreviewLink } from './JudgmentPreviewLink';
import { JudgmentsLink } from './JudgmentsLink';
import { JudgmentSignLink } from './JudgmentSignLink';
import { JudgmentBinLink } from './JudgmentBinLink';
import { CardWithJudgmentLink } from './CardWithJudgmentLink';
import { ExamLink } from './ExamLink';

interface JudgmentsSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableSingleSelect?: boolean;
  selectedKeys?: string[];
}

export const judgmentForGrid = (
  judgment: GetJudgmentExtendedDto,
  setRefresh?: () => void | undefined,
) => {
  return {
    key: judgment.id,
    values: {
      'judgment.number': (
        <>
          {judgment.active && <>{judgment.number}</>}
          {!judgment.active && (
            <div className="text-decoration-line-through">
              {judgment.number}
            </div>
          )}
        </>
      ),
      'examPackage.company':
        judgment?.companyName ||
        judgment?.exam?.examPackage?.company?.name ||
        '',
      'exam.number': (
        <>
          {judgment?.exam?.id && (
            <ExamLink
              examId={judgment?.exam?.id}
              articleMode={judgment?.article?.mode as unknown as ArticleMode}
            />
          )}{' '}
          {judgment?.exam?.number}
        </>
      ),
      'judgment.nextExamDate': momencik(judgment.nextExamDate),
      'judgment.judgmentDate': momencik(judgment.judgmentDate),
      'judgment.article': judgment?.article?.description || ' ',
      'article.mode':
        (judgment?.article?.mode as unknown as ArticleMode) ===
        ArticleMode.Psychologist
          ? 'psycholog'
          : 'lekarz',
      'exam.examined': judgment?.exam?.examined && (
        <>
          <ExaminedFromExamPreviewLink examId={judgment?.exam?.id} />{' '}
          {judgment?.exam.examined.surname} {judgment?.exam.examined.firstname}
        </>
      ),
      'examined.pesel':
        judgment?.exam?.examined?.pesel ||
        (judgment?.exam?.examined?.iDCardType || '') +
          ' ' +
          (judgment?.exam?.examined?.iDCardNumber || '') ||
        '',
      'judgment.psychologist': judgment?.psychologist?.name || '',
      'healthcareFacility.displayName':
        judgment?.exam?.examPackage?.healthcareFacility?.displayName || '',
      preview: (
        <>
          {judgment?.exam?.id && (
            <JudgmentsLink
              examId={judgment?.exam?.id}
              articleMode={judgment?.article?.mode as unknown as ArticleMode}
            />
          )}
          {judgment?.active && (
            <JudgmentPreviewLink
              judgmentId={judgment.id}
              engPdfTemplateId={judgment?.article?.engPdfTemplateId}
            />
          )}
        </>
      ),
      card: (
        <>
          {judgment?.exam?.id && judgment?.article?.register?.id && (
            <CardWithJudgmentLink
              examId={judgment?.exam?.id}
              active={judgment?.active || false}
              registerMode={
                judgment?.article?.register?.mode as unknown as RegisterMode
              }
            />
          )}
        </>
      ),
      emailStatus: (
        <>
          {judgment?.emailStatus !== undefined ? (
            <EmailStatusIcon
              status={judgment?.emailStatus as unknown as EmailStatus}
            />
          ) : (
            <>
              <EmailSendLink judgmentId={judgment.id} setRefresh={setRefresh} />
            </>
          )}
        </>
      ),
      sign: (
        <>
          {judgment?.active && !judgment.withoutSign && (
            <JudgmentSignLink judgmentId={judgment.id} />
          )}
        </>
      ),
      bin: (
        <>
          {judgment?.active && (
            <JudgmentBinLink judgmentId={judgment.id} setRefresh={setRefresh} />
          )}
        </>
      ),
    },
  };
};

export const JudgmentsSearch: React.FC<JudgmentsSearchProps> = props => {
  const columns = [
    { header: 'Data orzeczenia', property: 'judgment.judgmentDate' },
    { header: 'Numer', property: 'judgment.number' },
    { header: 'Nr badania', property: 'exam.number' },
    { header: 'Podstawa', property: 'judgment.article' },
    { header: 'Badany', property: 'exam.examined' },
    { header: 'PESEL / dok.', property: 'examined.pesel' },
    { header: 'Placówka', property: 'healthcareFacility.displayName' },
    { header: 'Firma', property: 'examPackage.company' },
    { header: 'Wystawił', property: 'article.mode' },
    {
      header: 'Psycholog/Lekarz',
      property: 'judgment.psychologist',
    },
    { header: 'Orzeczenie', property: 'preview', noSorting: true },
    { header: 'Karta', property: 'card', noSorting: true },
    { header: '', property: 'emailStatus', noSorting: true },
    { header: '', property: 'sign', noSorting: true },
    { header: '', property: 'bin', noSorting: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="judgment.judgmentDate"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak orzeczeń w systemie"
      refresh={props.refresh}
      enableSingleSelect={props.enableSingleSelect}
      selectedKeys={props.selectedKeys}
    />
  );
};
