/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateExamTypeForChannelDto
 */
export interface CreateUpdateExamTypeForChannelDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamTypeForChannelDto
     */
    examTypeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamTypeForChannelDto
     */
    appointmentChannelId?: string | null;
}

export function CreateUpdateExamTypeForChannelDtoFromJSON(json: any): CreateUpdateExamTypeForChannelDto {
    return CreateUpdateExamTypeForChannelDtoFromJSONTyped(json, false);
}

export function CreateUpdateExamTypeForChannelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateExamTypeForChannelDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'examTypeId': !exists(json, 'examTypeId') ? undefined : json['examTypeId'],
        'appointmentChannelId': !exists(json, 'appointmentChannelId') ? undefined : json['appointmentChannelId'],
    };
}

export function CreateUpdateExamTypeForChannelDtoToJSON(value?: CreateUpdateExamTypeForChannelDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'examTypeId': value.examTypeId,
        'appointmentChannelId': value.appointmentChannelId,
    };
}


