/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetHealthcareFacilityDto,
    GetHealthcareFacilityDtoFromJSON,
    GetHealthcareFacilityDtoFromJSONTyped,
    GetHealthcareFacilityDtoToJSON,
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoFromJSONTyped,
    GetUserDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetUserInHealthcareFacilityDto
 */
export interface GetUserInHealthcareFacilityDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserInHealthcareFacilityDto
     */
    id: string;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetUserInHealthcareFacilityDto
     */
    user?: GetUserDto | null;
    /**
     * 
     * @type {GetHealthcareFacilityDto}
     * @memberof GetUserInHealthcareFacilityDto
     */
    healthcareFacility?: GetHealthcareFacilityDto | null;
}

export function GetUserInHealthcareFacilityDtoFromJSON(json: any): GetUserInHealthcareFacilityDto {
    return GetUserInHealthcareFacilityDtoFromJSONTyped(json, false);
}

export function GetUserInHealthcareFacilityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserInHealthcareFacilityDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': !exists(json, 'user') ? undefined : GetUserDtoFromJSON(json['user']),
        'healthcareFacility': !exists(json, 'healthcareFacility') ? undefined : GetHealthcareFacilityDtoFromJSON(json['healthcareFacility']),
    };
}

export function GetUserInHealthcareFacilityDtoToJSON(value?: GetUserInHealthcareFacilityDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user': GetUserDtoToJSON(value.user),
        'healthcareFacility': GetHealthcareFacilityDtoToJSON(value.healthcareFacility),
    };
}


