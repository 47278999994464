/*eslint max-lines-per-function: ["error", 200]*/
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateAppointmentChannelDto,
  GetExamTypeDto,
  GetHealthcareFacilityDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { ReactSelectOption } from '../Selects/SortableSelect';
import ExamTypeMultiSelector from '../ExamTypes/ExamTypeMultiSelector';

interface AppointmentChannelEditorProps {
  id?: string;
  channel: CreateUpdateAppointmentChannelDto;
  handleClose?: () => void;
  show?: boolean;
  healthcareFacilities: GetHealthcareFacilityDto[];
  examTypes?: GetExamTypeDto[];
  onCompleted: (
    channel: CreateUpdateAppointmentChannelDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const AppointmentChannelEditor: React.FC<
  AppointmentChannelEditorProps
> = props => {
  const [channel, setChannel] = useState(props.channel);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [examTypes, setExamTypes] = useState<ReactSelectOption[]>([]);
  const notifications = useNotifications();
  const spd = useSpdCore();

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setChannel(props.channel);
      const bats =
        props.examTypes?.map(b => ({ value: b.id, label: b.name || '' })) || [];
      setExamTypes(bats);
      setChannel({ ...props.channel, examTypes: bats.map(o => o.value) });
    }
  }, [props.show, props.channel, props.examTypes]);

  const createUpdateAppointmentChannel = async () => {
    props
      .onCompleted(channel, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
      })
      .catch(error => {
        console.error('Error during add appointment channel:', error);
      });
  };

  const deleteAppointmentChannel = async () => {
    if (props.id) {
      notifications.onPromise(
        spd.appointmentChannels.changeToInactive(props.id),
        props.handleClose,
      );
    }
  };

  const getAllHealthcareFacilities = () => {
    const arr = props.healthcareFacilities.map(reg => {
      return {
        value: reg.id,
        label: reg.displayName,
      };
    });
    return arr;
  };

  const getSelectedHealthcareFacility = () => {
    return getAllHealthcareFacilities().filter(
      item => item.value === props.channel.healthcareFacilityId,
    );
  };

  const propertyChange = (obj: Partial<CreateUpdateAppointmentChannelDto>) => {
    setChannel({ ...channel, ...obj });
  };

  const handleClick = async (tempExamTypes: ReactSelectOption[]) => {
    setChannel({
      ...channel,
      examTypes: tempExamTypes.map(o => o.value),
    });
    setExamTypes(tempExamTypes);
  };

  return (
    <Modal
      onHide={props.handleClose}
      show={props.show}
      centered={true}
      keyboard={true}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.id
            ? 'Edycja kanału rezerwacji wiyzt'
            : 'Dodawanie nowego kanału rezerwacji wizyt'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-2">
          <Card.Header>Kanał rezerwacji wizyt</Card.Header>
          <Card.Body>
            <Form className="d-grid gap-3">
              <FormRow controlId="channel_name" label="Nazwa">
                <Form.Control
                  type="text"
                  value={channel?.name || ''}
                  onChange={e => propertyChange({ name: e.target.value })}
                />
              </FormRow>
              <FormRow
                controlId="channel_description"
                label="Opis (wewnętrzny)"
              >
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={channel?.description || ''}
                  onChange={e =>
                    propertyChange({ description: e.target.value })
                  }
                />
              </FormRow>
              <FormRow controlId="healthcareFacility" label="Placówka">
                <Select
                  options={getAllHealthcareFacilities()}
                  defaultValue={getSelectedHealthcareFacility()}
                  onChange={options =>
                    propertyChange({ healthcareFacilityId: options?.value })
                  }
                />
              </FormRow>
              <FormRow
                controlId="examType_timeWindow"
                label="Okno czasowe (minuty)"
              >
                <Form.Control
                  type="number"
                  value={channel?.timeWindow || 0}
                  onChange={e => propertyChange({ timeWindow: e.target.value })}
                />
              </FormRow>
              <FormRow controlId="channel_public" label="Publiczny">
                <Form.Check
                  type="checkbox"
                  checked={channel?.channelPublic ? true : false}
                  onChange={e =>
                    propertyChange({ channelPublic: e.target.checked })
                  }
                />
              </FormRow>
              <FormRow controlId="examTypes" label="Typy badań">
                <ExamTypeMultiSelector
                  selectedExamType={examTypes}
                  onChange={options => handleClick((options || []).map(o => o))}
                />
              </FormRow>
              {/* <FormRow controlId="user_popular" label="Aktywny">
                <Form.Check
                  type="checkbox"
                  readOnly={true}
                  checked={examType?.active ? true : false}
                  onChange={e => propertyChange({ popular: e.target.active })}
                />
              </FormRow> */}
            </Form>
          </Card.Body>
        </Card>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
      </Modal.Body>
      <Modal.Footer>
        {props.id && (
          <LastEditorInfo
            id={props.id}
            method={spd.appointmentChannels.getLastEditor}
          />
        )}
        <Button variant="outline-secondary" onClick={props.handleClose}>
          Zamknij
        </Button>
        {props.id && (
          <ConfirmationButton
            className="mx-1"
            confirmation="Czy na pewno usunąć kanał rezerwacji wizyt?"
            variant="outline-danger"
            onOK={deleteAppointmentChannel}
          >
            Usuń
          </ConfirmationButton>
        )}
        <Button
          variant="outline-primary"
          onClick={createUpdateAppointmentChannel}
        >
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
