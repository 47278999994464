/*eslint max-lines-per-function: ["error", 400]*/
import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  CreateUpdateExamDto,
  GetExamDto,
  GetLastEditorDto,
} from '../autogenerated/spdApiClient';
import { examForGrid } from '../../../spd/components/Exam/ExamSearch';
import {
  CreateUpdateExamWithTestsDto,
  CreateUpdateExamWithTestsDtoFromJSON,
} from '../autogenerated/spdApiClient/models/CreateUpdateExamWithTestsDto';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { ArticleMode } from '../validation/schemas';
// import { validateAgainst } from '../validation/validateAgainst';

export type ExamEntity = GetExamDto;

export interface ExamContextInterface {
  getExam: (id: string) => Promise<ExamEntity>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    mode?: ArticleMode,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string, mode?: ArticleMode) => Promise<number>;
  getWithExaminedByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    mode?: ArticleMode,
  ) => Promise<GridRecord[]>;
  getWithExaminedCount: (
    filterText?: string,
    mode?: ArticleMode,
  ) => Promise<number>;
  mode?: ArticleMode;
  getWithoutJudgmentByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    mode?: ArticleMode,
  ) => Promise<GridRecord[]>;
  getWithoutJudgmentCount: (
    filterText?: string,
    mode?: ArticleMode,
  ) => Promise<number>;
  getForSpecialistByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    mode?: ArticleMode,
  ) => Promise<GridRecord[]>;
  getForSpecialistCount: (
    filterText?: string,
    mode?: ArticleMode,
  ) => Promise<number>;
  getActiveByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    mode?: ArticleMode,
  ) => Promise<GridRecord[]>;
  getActiveCount: (filterText?: string, mode?: ArticleMode) => Promise<number>;
  getForPackageByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    examPackageId?: string,
  ) => Promise<GridRecord[]>;
  getForPackageCount: (
    filterText?: string,
    examPackageId?: string,
  ) => Promise<number>;
  getForExaminedByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    examinedId?: string,
  ) => Promise<GridRecord[]>;
  getForExaminedCount: (
    filterText?: string,
    examinedId?: string,
  ) => Promise<number>;
  update: (id: string, dto: CreateUpdateExamDto) => Promise<void>;
  createWithTests: (dto: CreateUpdateExamWithTestsDto) => Promise<GetExamDto[]>;
  updateWithTests: (
    examId: string,
    dto: CreateUpdateExamWithTestsDto,
  ) => Promise<GetExamDto>;
  changeDates: (id: string, endDate: Date, startDate?: Date) => Promise<void>;
  changeToInactive: (id: string) => Promise<void>;
  changeAllCards: () => Promise<void>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const ExamContext = (api: DefaultApi) => {
  return {
    getExam: async (id: string) => {
      return await api.examControllerGet(id);
    },

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      mode?: ArticleMode,
    ) => {
      const data = await api.examControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        mode,
      );
      return data.map(examForGrid);
    },

    getAllCount: (filterText?: string, mode?: ArticleMode) =>
      api.examControllerGetAllCount(filterText || '', mode),

    getWithExaminedByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      mode?: ArticleMode,
    ) => {
      const data = await api.examControllerGetWithExaminedByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        mode,
      );
      return data.map(examForGrid);
    },

    getWithExaminedCount: (filterText?: string, mode?: ArticleMode) =>
      api.examControllerGetWithExaminedCount(filterText || '', mode),

    getActiveByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      mode?: ArticleMode,
    ) => {
      const data = await api.examControllerGetActiveByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        mode,
      );
      return data.map(examForGrid);
    },

    getActiveCount: (filterText?: string, mode?: ArticleMode) =>
      api.examControllerGetActiveCount(filterText || '', mode),

    getWithoutJudgmentByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      mode?: ArticleMode,
    ) => {
      const data = await api.examControllerGetWithoutJudgmentByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        mode,
      );
      return data.map(examForGrid);
    },

    getWithoutJudgmentCount: (filterText?: string, mode?: ArticleMode) =>
      api.examControllerGetWithoutJudgmentCount(filterText || '', mode),

    getForSpecialistByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      mode?: ArticleMode,
    ) => {
      const data = await api.examControllerGetForSpecialistByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        mode,
      );
      return data.map(examForGrid);
    },

    getForSpecialistCount: (filterText?: string, mode?: ArticleMode) =>
      api.examControllerGetForSpecialistCount(filterText || '', mode),

    getForPackageByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      examPackageId?: string,
    ) => {
      const data = await api.examControllerGetForPackageByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        examPackageId || '',
      );
      return data.map(examForGrid);
    },

    getForPackageCount: (filterText?: string, examPackageId?: string) =>
      api.examControllerGetForPackageCount(
        filterText || '',
        examPackageId || '',
      ),

    getForExaminedByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      examinedId?: string,
    ) => {
      const data = await api.examControllerGetForExaminedByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        examinedId || '',
      );
      return data.map(examForGrid);
    },

    getForExaminedCount: (filterText?: string, examinedId?: string) =>
      api.examControllerGetForPackageCount(filterText || '', examinedId || ''),

    update: (id: string, dto: CreateUpdateExamDto) =>
      api.examControllerUpdate(id, dto),

    createWithTests: (dto: CreateUpdateExamWithTestsDto) =>
      api.examControllerCreateWithTests(dto),

    updateWithTests: (examId: string, dto: CreateUpdateExamWithTestsDto) =>
      api.examControllerUpdateWithTests(examId, dto),

    changeDates: (id: string, endDate: Date, startDate?: Date) =>
      api.examControllerChangeDates(id, {
        startDate: startDate,
        endDate: endDate,
      }),

    changeToInactive: (id: string) => api.examControllerChangeToInactive(id),
    changeAllCards: () => api.pdfPrinterControllerChangeAllCards(),
    getLastEditor: (id: string) => api.examControllerGetLastEditor(id),
  };
};

// export const validate = (exam: CreateUpdateBadanieDto) =>
//   validateAgainst(ExamSchema, examined);

export const examData = (exam?: GetExamDto): CreateUpdateExamWithTestsDto => {
  return CreateUpdateExamWithTestsDtoFromJSON({
    ...exam,
    healthcareFacilityId: exam?.examPackage?.healthcareFacility?.id,
    examPackageId: exam?.examPackage?.id,
  });
};
