import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  CreateUpdateSupportingExaminationDto,
  CreateUpdateSupportingExaminationDtoTypeEnum,
  GetSupportingExaminationDto,
  GetSupportingExaminationDtoTypeEnum,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { SupportingExamination } from '../../components/SupportingExamination/SupportingExamination';
import { SupportingExaminationEditor } from '../../components/SupportingExamination/SupportingExaminationEditor';
import { supportData } from '../../../common/spdCore/contexts/SupportingExaminationContext';
import { SupportingType } from '../../../common/spdCore/validation/schemas';

interface SupportingExaminationProps {
  examId: string;
  mode: SupportingType;
  cardId?: string;
}

export const SupportingExaminationView: React.FC<
  SupportingExaminationProps
> = props => {
  const spd = useSpdCore();

  const [show, setShow] = useState(false);
  const [support, setSupport] = useState<GetSupportingExaminationDto>();
  const [supports, setSupports] = useState<GetSupportingExaminationDto[]>([]);

  const mountedRef = useRef(false);

  const refreshSupports = useCallback(() => {
    if (!mountedRef.current) {
      return;
    }
    spd.support
      .getAllByExam(props.examId)
      .then(sup => setSupports(sup))
      .catch(error => {
        console.error(
          'Wystąpił problem z pobraniem konsultacji specjalistycznych i badań pomocniczych:',
          error,
        );
      });
  }, [spd.support, props.examId]);

  useEffect(() => {
    mountedRef.current = true;
    refreshSupports();
    return () => {
      mountedRef.current = false;
    };
  }, [refreshSupports]);

  const handleAdd = () => {
    setShow(true);
    setSupport(undefined);
  };

  const addEditSupport = async (
    supportCu: CreateUpdateSupportingExaminationDto,
    id?: string,
  ) => {
    supportCu.examId = props.examId;
    supportCu.type =
      props.mode as unknown as CreateUpdateSupportingExaminationDtoTypeEnum;
    // const status = await validate(supportCu);
    // if (status.valid) {

    try {
      let resultId = id;
      if (id) {
        await spd.support.update(id, supportCu);
      } else {
        resultId = (await spd.support.create(supportCu)).id;
      }
      setShow(false);
      refreshSupports();
      return {
        id: resultId,
        saved: true,
        errors: [],
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as Response),
      };
    }
    // } else {
    //   return {
    //     saved: false,
    //     errors: status.errors,
    //   };
    // }
  };

  const handleClose = () => {
    setShow(false);
    refreshSupports();
  };

  const handleClick = async (id: string) => {
    const getSupport = await spd.support.get(id);
    setSupport(getSupport);
    setShow(true);
  };

  return (
    <>
      <SupportingExamination
        data={supports.filter(
          s => (s.type as unknown as SupportingType) === props.mode,
        )}
        onRowClick={handleClick}
        type={props.mode}
      />
      {props.cardId && (
        <Button variant="outline-primary" onClick={handleAdd}>
          {props.mode === SupportingType.specialisedConsultation
            ? 'Dodaj konsultację'
            : 'Dodaj badanie'}
        </Button>
      )}
      {!props.cardId && (
        <Alert>
          Przed dodaniem badania pomocniczego lub konsultacji specjalistycznej
          należy zapisać kartę badania
        </Alert>
      )}
      <SupportingExaminationEditor
        id={support?.id}
        support={supportData(
          supports.filter(
            o =>
              o.type ===
              (props.mode as unknown as GetSupportingExaminationDtoTypeEnum),
          ).length + 1,
          props.cardId,
          support,
        )}
        onCompleted={addEditSupport}
        show={show}
        handleClose={handleClose}
        type={props.mode}
        cardId={props.cardId}
      />
    </>
  );
};
