import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetExamTypeCategoryDto } from '../../../common/spdCore/autogenerated/spdApiClient';

interface ExamTypeCategoryProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  refresh?: boolean;
  onRowClick: (key: string) => Promise<void> | void | undefined;
}

export const examTypeCategoryForGrid = (
  examTypeCategory: GetExamTypeCategoryDto,
) => {
  return {
    key: examTypeCategory.id,
    values: {
      'examTypeCategory.name': examTypeCategory.name,
      'examTypeCategory.active': examTypeCategory.active ? 'Tak' : 'Nie',
    },
  };
};

export const ExamTypeCategorySearch: React.FC<
  ExamTypeCategoryProps
> = props => {
  const columns = [
    { header: 'Nazwa', property: 'examTypeCategory.name' },
    { header: 'Aktywny', property: 'examTypeCategory.active' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      onRowClick={props.onRowClick}
      initialOrderColumn="examTypeCategory.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak kategorii typów badań w systemie"
      enableMultiSelect={false}
      refresh={props.refresh}
    />
  );
};
